<div class="info-section-main">
    <div class="stat-heading mb-2">
        Company info
    </div>
    <div class="underline mb-2"></div>
    <div *ngIf="company?.legalName" class="stat-heading mb-1">Company:
        <span class="font-weight-400">{{company?.legalName}}</span>
    </div>
    <div *ngIf="company?.yearFounded" class="stat-heading mb-1">Year Founded:
        <span class="font-weight-400">{{company?.yearFounded}}</span>
    </div>
    <div *ngIf="company?.numberOfEmployees" class="stat-heading mb-1">
        Employees:
        <span class="font-weight-400">{{company?.numberOfEmployees}}</span>
    </div>
    <div *ngIf="company?.headquarters" class="stat-heading mb-1">HQ:
        <span class="font-weight-400">{{company?.headquarters}}</span>
    </div>
    <div *ngIf="company?.categoryId" class="stat-heading mb-1">Category:
        <span (click)="OpenCompanyCategory()"
            class="font-weight-400 category clickable-link">{{staticDataService.categoriesMap?.get(company.categoryId)?.name}}
        </span>
    </div>
    <div *ngIf="company?.category" class="stat-heading mb-1">Industry:
        <span class="font-weight-400">{{company?.category}}</span>
    </div>
</div>