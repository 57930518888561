export class FeedItem {
  ytVideoId: string = "";
  isInViewport: boolean = false;
  adSpend30: number = 0;
  adSpend90: number = 0;
  totalSpend: number = 0;
  publishedAt: string = "";
  isLiked: boolean = false;
  categoryId: number = 0;
  isDisliked: boolean = false;
}
