import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-share-button",
  templateUrl: "./share-button.component.html",
  styleUrls: ["./share-button.component.scss"],
})
export class ShareButtonComponent {
  @Input() copyUrl: string = "";
  @Input() type: string = "";
  @Input() ytVideoId: string = "";
  @Input() brandId: number = 0;
  @Input() categoryId: number = 0;
}
