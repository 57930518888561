<div>
    <ng-container *ngIf="!isLiked && !isDisliked">
        <i (click)="SetLikeDislike(true,false)" class='bx bx-like like-icon'></i>
        <i (click)="SetLikeDislike(false,true)" class='bx bx-dislike like-icon'></i>
    </ng-container>
    <ng-container *ngIf="isLiked && !isDisliked">
        <i (click)="SetLikeDislike(false,false)" class='bx bxs-like like-icon'></i>
        <i (click)="SetLikeDislike(false,true)" class='bx bx-dislike like-icon'></i>
    </ng-container>
    <ng-container *ngIf="!isLiked && isDisliked">
        <i (click)="SetLikeDislike(true,false)" class='bx bx-like like-icon'></i>
        <i (click)="SetLikeDislike(false,false)" class='bx bxs-dislike like-icon'></i>
    </ng-container>
</div>