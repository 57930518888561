import { AfterViewInit, Component, OnInit } from "@angular/core";
import { AffiliateOffer } from "src/app/models/affiliateOffer";
import { PageSizes } from "src/app/models/consts";
import { ModuleType } from "src/app/models/enums";
import { GetNetworkAdsStats } from "src/app/models/getNetworkAdsStats";
import { GetNetworkAffiliateStats } from "src/app/models/getNetworkAffiliateStats";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-affiliate-networks",
  templateUrl: "./affiliate-networks.component.html",
  styleUrl: "./affiliate-networks.component.scss",
})
export class AffiliateNetworksComponent implements OnInit, AfterViewInit {
  pageSizes = PageSizes;
  resultsPerPage = this.pageSizes[3];
  page: number = 1;
  selectedNetworkId: number = 0;

  networkAdsStats: GetNetworkAdsStats = new GetNetworkAdsStats();
  networkAffiliateStats: GetNetworkAffiliateStats =
    new GetNetworkAffiliateStats();
  topOffers: AffiliateOffer[] = [];
  topLanguages: any[] = [];
  topLanguagesString: string = "";
  xValues: number[] = [];
  yValues: string[] = [];

  constructor(
    public stateService: StateService,
    public apiService: ApiService,
    public popupService: PopupService,
    public staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("networks");
    this.stateService.ModuleSelected(ModuleType.Affiliate);
    this.LoadData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SubscribeToUser();
    }, 300);
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      if (!user) return;
      if (!user?.hasWatchedAffiliate) this.popupService.OpenAffiliateTutorial();
    });
  }

  public LoadData() {
    this.ResetValues();
    this.LoadNetworkAdsCount();
    this.LoadNetworkActiveAdsCount();
    this.LoadNetworkOffersCount();
    this.LoadNetworkActiveAffiliatesCount();
    this.LoadNetworkTopOffers();
    this.LoadNetworkTopLanguages();
  }

  public ResetValues() {
    this.networkAdsStats.adsCount = 0;
    this.networkAdsStats.activeAdsCount = 0;
    this.networkAffiliateStats.activeAffiliatesCount = 0;
    this.networkAffiliateStats.offersCount = 0;
    this.topLanguagesString = "";
  }

  public ResultsPerPageChanged(resultsPerPage: number) {
    this.resultsPerPage = resultsPerPage;
    this.page = 1;
  }

  public CurrentPageChanged(currentPage: number) {
    this.page = currentPage;
  }

  public SelectedNetworkChanged(networkId: number) {
    this.selectedNetworkId = networkId;
    this.LoadData();
  }

  public async LoadNetworkAdsCount() {
    const result = await this.apiService.GetNetworkAdsCount(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) this.networkAdsStats.adsCount = result?.data.adsCount;
  }

  public async LoadNetworkActiveAdsCount() {
    const result = await this.apiService.GetNetworkActiveAdsCount(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.networkAdsStats.activeAdsCount = result?.data.activeAdsCount;
  }

  public async LoadNetworkOffersCount() {
    const result = await this.apiService.GetNetworkOffersCount(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.networkAffiliateStats.offersCount = result?.data.offersCount;
  }

  public async LoadNetworkActiveAffiliatesCount() {
    const result = await this.apiService.GetNetworkActiveAffiliatesCount(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.networkAffiliateStats.activeAffiliatesCount =
        result?.data.activeAffiliatesCount;
  }

  public async LoadNetworkTopOffers() {
    const result = await this.apiService.GetNetworkTopOffers(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data?.results) {
      this.topOffers = result.data.results;
      this.xValues = this.topOffers.map((o) => o.totalSpend);
      this.yValues = this.topOffers.map((o) => o.name);
    }
  }

  public async LoadNetworkTopLanguages() {
    const result = await this.apiService.GetNetworkTopLanguages(
      this.selectedNetworkId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data?.results) {
      this.topLanguages = result.data.results;
      this.topLanguagesString = this.topLanguages
        .filter((l) => l.languageId != "")
        .map((l) => this.staticDataService.languagesMap.get(l.languageId))
        .join(",");
    }
  }

  public OpenAffiliateTutorial() {
    this.popupService.OpenAffiliateTutorial();
  }
}
