import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PageSizes } from "src/app/models/consts";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { Brand } from "src/app/models/brand";
import { ModuleType } from "src/app/models/enums";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { FeedDataService } from "src/app/services/shared/feed-data.service";

@Component({
  selector: "app-brands-list",
  templateUrl: "./brands-list.component.html",
  styleUrls: ["./brands-list.component.scss"],
})
export class BrandsListComponent implements OnInit, OnChanges {
  searchResultsBrands!: SearchResponse<Brand>;
  allBrands: Brand[] = [];
  displayedBrands: Brand[] = [];
  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];
  @Input() currentPageBrands = 1;
  totalPages: number = 0;

  @Input() categoryIds: number[] = [];
  @Input() softwareIds: number[] = [];
  @Input() countryId: number = 0;
  @Input() languageId: string = "";

  @Input() type: string = "brands";
  @Input() companyId: number = 0;
  @Input() keyword: string = "";
  @Input() filterKeyword: string = "";
  @Input() isPayingUser: boolean = true;

  @Input() durationId: string = "";
  @Input() isSwipes: boolean = false;
  durationMoreThan: number | undefined = undefined;
  durationLessThan: number | undefined = undefined;

  sortProp = "total";
  orderAsc = false;
  sortingIndex: number = 1;

  contentLoaded: boolean = false;
  activeIndex: number = 1;

  @Output() brandsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() selectedBoardId: number = 0;
  @Output() boardsSelected: EventEmitter<object> = new EventEmitter<object>();
  @Input() shareBoardId: string = "";
  @Input() optionalUrl: string = "";
  @Input() encryptedId: string = "";
  @Input() loadedParams: boolean = false;
  @Input() searchByKeyword: boolean = false;

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() categorySelected: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public apiService: ApiService,
    private popupService: PopupService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    private popup: PopupService,
    public feedService: FeedDataService
  ) {}

  ngOnInit(): void {
    this.LoadSwipeBoards();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      await this.LoadData();
      this.brandsLoaded.emit(true);
    }
  }

  private async LoadData() {
    this.contentLoaded = false;
    if (this.type == "brands") {
      [this.durationMoreThan, this.durationLessThan] = this.ParseDuration(
        this.durationId
      );
      await this.LoadBrands();
    } else if (this.type == "company-brands") await this.LoadBrandsForCompany();
    else if (this.type == "swiped-brands") await this.LoadSwipedBrands();
  }

  public async LoadBrands() {
    const result = await this.apiService.FullSearchBrands(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPageBrands,
        this.sortProp,
        this.orderAsc,
        false,
        [],
        this.categoryIds,
        this.countryId,
        undefined,
        this.durationMoreThan,
        this.durationLessThan,
        undefined,
        this.softwareIds
      )
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsBrands = result.data;
      this.allBrands = result.data.results;
      this.displayedBrands = [...this.allBrands];
    }

    this.feedService.BrandAdvertiserSearch({
      keyword: this.keyword,
      categoryIds: this.categoryIds,
      contryId: this.countryId,
      softwareIds: this.softwareIds,
      ...(this.durationMoreThan &&
        this.durationLessThan && {
          duration: this.durationMoreThan + "-" + this.durationLessThan,
        }),
    });
  }
  public async LoadBrandsForCompany() {
    const result = await this.apiService.LoadBrandsForCompany(
      this.companyId,
      this.isPayingUser,
      this.optionalUrl,
      this.encryptedId
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsBrands = result.data;
      this.currentPageBrands = 1;
      this.PaginateBrands();
    }
  }
  private PaginateBrands() {
    if (this.searchResultsBrands.results) {
      const start = (this.currentPageBrands - 1) * this.resultsPerPage;
      const end =
        (this.currentPageBrands - 1) * this.resultsPerPage +
        this.resultsPerPage;
      this.displayedBrands = this.searchResultsBrands.results?.slice(
        start,
        end
      );
      if (
        this.displayedBrands.length <
        this.searchResultsBrands.results.slice(start, end + 1).length
      )
        this.searchResultsBrands.hasMore = true;
      else this.searchResultsBrands.hasMore = false;
    }
  }
  public async LoadSwipedBrands() {
    let result;
    if (!this.shareBoardId)
      result = await this.apiService.GetSwipedBrands(
        this.currentPageBrands,
        this.resultsPerPage,
        this.keyword,
        this.sortProp,
        this.orderAsc,
        this.selectedBoardId
      );
    else
      result = await this.apiService.GetSharedBoardBrands(
        this.currentPageBrands,
        this.resultsPerPage,
        this.keyword,
        this.sortProp,
        this.orderAsc,
        this.shareBoardId
      );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsBrands = result.data;
      this.allBrands = result.data.results;
      this.displayedBrands = [...this.allBrands];
    }
  }
  public async NewChunkBrands(page: number) {
    this.currentPageBrands = page;
    if (this.type == "brands") await this.LoadBrands();
    else if (this.type == "swiped-brands") await this.LoadSwipedBrands();
    //if company-brands
    else this.PaginateBrands();
  }

  public async ChangePageBrands(newPage: number) {
    if (!this.searchByKeyword) await this.NewChunkBrands(Number(newPage));
    this.currentPageChanged.emit(newPage);
  }

  async onPageSizeChange(value: string) {
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    this.currentPageBrands = 1;
    if (!this.searchByKeyword) {
      if (this.type == "brands") this.LoadBrands();
      else if (this.type == "swiped-brands") await this.LoadSwipedBrands();
      else this.PaginateBrands();
    }
    this.resultsPerPageChanged.emit(this.resultsPerPage);
  }

  public async SetSorting(index: number, sortProp: string, sortOrder: boolean) {
    this.currentPageBrands = 1;
    this.sortingIndex = index;
    this.sortProp = sortProp;
    this.orderAsc = sortOrder;
    this.currentPageBrands = 1;
    await this.LoadData();
  }

  public OpenSelectedBrand(brand: Brand) {
    const currentRoute = this.route.snapshot.routeConfig?.path;
    // if (this.type == "brand")
    //   this.router.navigate(['dashboard/entity-info'], { queryParams: { brandId: brand.brandId } })
    // else
    // this.popup.OpenBrandPreview(brand.brandId, currentRoute);
    this.router.navigate(["dashboard/entity-info"], {
      queryParams: { brandId: brand.brandId },
    });
  }

  public async OpenSelectedCategory(event: Event, categoryId: number) {
    event.stopPropagation();
    if (this.isPayingUser) {
      this.stateService.ModuleSelected(ModuleType.Marketer); // if in affiliate mode to switch to marketer before opening ranking page
      await this.router.navigate(["/dashboard/rankings"], {
        queryParams: { selectedCategoryId: categoryId },
      });
    } else this.popupService.AskForAccountUpgrade();
  }

  public ParseDuration(duration: string) {
    return duration.split("-").map((str) => {
      return parseInt(str);
    });
  }

  public BoardsSelected(boardIds: number[], brandId: number) {
    this.boardsSelected.emit({ boardIds, brandId });
  }

  public async DeleteBrandSwipe(brandId: number) {
    const result = await this.apiService.RemoveSwipedBrand(brandId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    this.popupService.Notify("Brand successfuly removed from Swipes.");
    await this.LoadData();
  }

  public RemoveBrandSwipe(brand: Brand) {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected brand?",
      async () => {
        await this.DeleteBrandSwipe(brand.brandId);
        brand.isSwiped = false;
      }
    );
  }

  public async BrandSwipeSaved(swipeBoardIds: number[], brand: Brand) {
    const result = await this.apiService.SwipeBrand(
      brand.brandId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(`${brand.name} successfuly saved to favorites!`);
    brand.isSwiped = true;
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public GetBrandLink(brandId: number) {
    return (
      window.location.origin + "/#/dashboard/entity-info?brandId=" + brandId
    );
  }

  public AddSelectedCategoryToFilter(event: Event, categoryId: number) {
    event.stopPropagation();
    this.categorySelected.emit(categoryId);
  }
}
