<mat-dialog-content style="padding: 40px !important">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 40px;" src="assets/icons/logo-black-notext.png" />
        <img (click)="CloseModal()" class="my-auto position-absolute" style="height: 24px; right: 5px; cursor: pointer;"
            src="assets/icons/x-close.png" />
    </div>
    <h1 class="premium-title text-center mb-1 mt-4">
        Upgrade to VidTao Premium to get unlimited access to the YouTube Ad Database:
    </h1>
    <h4 class="text-center mt-4 sub-title5">24h free, then $67/month (+ tax)</h4>
    <ul class="mt-5">
        <li class="list-item">Full access to the ad database, premium features, and filters</li>
        <li class="list-item">Free use of our internal YouTube ad training library created by Inceptly agency, the team
            behind $950M+ in direct response revenue.</li>
        <li class="list-item">10% Savings on all VidTao Live events</li>
        <li class="list-item">Exclusive access to new features</li>
        <li class="list-item">Cancel any time</li>
    </ul>
    <div class="prices d-block text-center mt-4">
        <p style="font-weight: 550; text-align: end; font-size: 18px;" class="mb-0 ml-auto">
            {{subscriptionPlan.name}}
        </p>
        <p style="font-weight: 550; text-align: end; font-size: 18px;" class="mb-0 ml-auto">Price:
            ${{subscriptionPlan.price}}
        </p>
    </div>
    <p class="list-item please-enter mt-3">
        Please enter your details
    </p>
    <P class="list-item">
        We collect this information to help combat fraud, and to keep your payment secure.
    </P>
    <div class='checkout-container'></div>
</mat-dialog-content>