<div (click)="CheckIfClickAllowed($event)" class="container container-900">
    <section [ngClass]="!displayTopSection ? 'd-none d-sm-block': ''" class="mt-3 mb-3 justify-content-between">
        <div class="row w-100 mx-0">

            <div class="pr-2 col-12 col-md-8">
                <h1 class="title">{{video.title}}</h1>
                <p class="text-desc-2 mb-0">Brand: <span [ngStyle]="getBlurIfNeeded()" (click)="OpenVideoBrand()"
                        class="colored-text clickable-link">{{video?.brandName}}</span></p>
                <div class="d-flex flex-row flex-wrap mt-2">
                    <p class="mb-0 mr-2 text-desc-2">Category: </p>
                    <div class="d-flex">
                        <div (click)="OpenSelectedCategory()" [ngStyle]="getBlurIfNeeded()"
                            class="category text-desc-2 clickable-link">
                            {{staticDataService.categoriesMap?.get(video.categoryId)?.name}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons-div d-flex flex-column justify-content-between col-12 col-md-4 mt-2 mt-sm-0 ">
                <div class="d-flex flex-wrap flex-column mt-2">
                    <div class="d-flex flex-column align-items-center">
                        <swipe-button *ngIf="user" [swipeBoards]="swipeBoards" class="w-100" [isSwiped]="video.swiped"
                            (swipeSaved)="SwipeSaved($event)" (swipeRemoved)="RemoveSwipe()"></swipe-button>
                        <app-share-button [ytVideoId]="ytVideoId" [brandId]="video.brandId"
                            [categoryId]="video.categoryId" [type]="'video'" class="mt-2 w-100" *ngIf="shareUrl"
                            [copyUrl]="ShareUrl"></app-share-button>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <div class="d-flex mt-2">
        <app-yt-player-controls (watching30Seconds)="Watching30Seconds()" (watching80Percent)="Watching80Percent()"
            class="w-100" [time]="jumpTime" [ytVideoId]="ytVideoId" (freeIntervalPassed)="FreeIntervalPassed()"
            (videoStarted)="VideoStarted()"></app-yt-player-controls>
    </div>
    <div *ngIf="videoTranscripts.length > 0" class="d-flex justify-content-center my-3">
        <button (click)="ViewTranscript()" class="btn transparent-button">{{ displayTranscript ?
            'Hide transcript' : 'View transcript' }}</button>
    </div>
    <section [ngClass]="displayTranscript ? 'd-block': 'd-none'">
        <div class="d-flex flex-wrap">
            <ng-container *ngFor="let transcript of videoTranscripts">
                <div class="d-flex">
                    <p (click)="JumpToVideoSeconds(transcript.moment)" class="mb-1 mr-1 section-desc link-text">
                        {{transcript.moment}}</p>
                    <p class="mb-1 mr-3 section-desc">{{transcript.text}}</p>
                </div>
            </ng-container>
        </div>
    </section>
    <section class="mt-5 p-0 overflow-hidden">
        <table>
            <thead style="border-top: none">
                <tr>
                    <th class="text-center">Frame</th>
                    <th>Total ad spend</th>
                    <th>Ad spend 30</th>
                    <th>First seen</th>
                    <th>Last seen</th>
                    <th>Publish Date</th>
                    <th>Duration</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" class="logo-display" data-label="Frame">
                        <span>
                            <div class="icon-container ml-4 thumbnail">
                                <img style="width: 40px; height: 40px;" [src]='video.thumbnail' />
                            </div>
                        </span>
                    </td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="Total ad spend"><span>
                            ${{(video.totalSpend || 0)
                            |
                            valueFormat}}
                        </span>
                    </td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="Ad spend 30"><span>
                            ${{(video.last30Days || 0) |
                            valueFormat}}
                        </span>
                    </td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="First seen">
                        <span>
                            <ng-container *ngIf="video.firstSeen">
                                {{video.firstSeen | dateFormat}}
                            </ng-container>
                            <ng-container *ngIf="!video.firstSeen">
                                <i>No data</i>
                            </ng-container>
                        </span>
                    </td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="Last seen">
                        <span>
                            <ng-container *ngIf="video.isActive">
                                <i>Active</i>
                            </ng-container>
                            <ng-container *ngIf="!video.isActive && video.lastSeen">
                                {{video.lastSeen | dateFormat}}
                            </ng-container>
                            <ng-container *ngIf="!video.isActive && !video.lastSeen">
                                <i>No data</i>
                            </ng-container>
                        </span>
                    </td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="Publish Date"><span>
                            {{video.publishedAt |
                            dateFormat}}
                        </span></td>
                    <td [ngClass]="{ 'td-blur': !isPayingUser }" data-label="Duration"><span>
                            {{video.duration |
                            durationFormat}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>

    <div class="mt-4 mb-4">
        <div class="row mx-0">
            <div class="col-12 px-0 mb-4 mb-md-0">
                <section class="flex-column h-100">
                    <app-line-chart [isPayingUser]="isPayingUser" [isVideoChart]="true" [yValues]="yValues"
                        [xValues]="xValues" [dateRangeOptions]="['30 days', '12 months', 'Total']"
                        selectedChartPeriod='12 months' chartId='videoInfo' yTitle="Estimated ad spend(USD)"
                        xTitle="Period" type="line" timeUnit="day" (dateRangeChanged)="ChangeGraphDateRange($event)">
                    </app-line-chart>
                </section>

            </div>
        </div>
    </div>


    <div class="row d-flex flex-wrap mt-4 mb-4 mx-0">
        <div class="col-12 col-md-6 px-0 mb-4 mb-md-0">
            <section *ngIf="videoStats.clickUrls.length > 0" class="d-flex mr-md-3  flex-column">
                <p class="title2 mb-0">Landing pages</p>
                <div class="underline mt-1 mb-2"></div>
                <div [ngStyle]="getBlurIfNeeded()" style="cursor: pointer;"
                    *ngFor="let clickUrl of videoStats.clickUrls" class="d-flex flex-row align-items-center mb-2">
                    <ng-container *ngIf="clickUrl.url">
                        <img class="mr-2" style="height: 25px; width: 25px;" src="assets/icons/globe.png">
                        <a (click)="OpenInNewTab(clickUrl.url)" class="text-desc-2 url mb-0 break-word restricted">
                            {{clickUrl.urlDisplay}}
                        </a>
                    </ng-container>
                </div>
            </section>
        </div>
        <div class="col-12 col-md-6 px-0">
            <section *ngIf="videoStats.countries.length > 0" class="d-flex  flex-column">
                <p class="title2 mb-0">Target countries</p>
                <div class="underline mt-1 mb-2"></div>

                <div [ngStyle]="getBlurIfNeeded()" class="text-container" [ngClass]="{ 'expanded': isExpanded }">
                    <div class="info-text video-text-content">
                        <p *ngFor="let country of videoStats.countries" class="text-desc-2 d-flex">
                            <app-flag [countryCode]="country.code"></app-flag>
                            <span class="text-desc-2"
                                style="align-self: center; margin-left: 5px;">{{country.code.toUpperCase()}}
                                - ({{country.count}}
                                campaign(s))</span>
                        </p>
                    </div>
                    <button (click)="ToggleExpand()" class="expand-button form-control mx-auto">
                        <span *ngIf="!isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-down'></i></span>
                        <span *ngIf="isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-up'></i></span>
                    </button>
                </div>
            </section>
        </div>
    </div>
    <section *ngIf="video.description" class="d-block mb-2">
        <p class="text-desc-2">
            <span class="light-dark">Video description:</span>
            <span [ngStyle]="getBlurIfNeeded()">{{video.description}}</span>
        </p>
    </section>
</div>