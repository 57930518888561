import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

enum EventType {
  AdsSearch = "Ads search",
  BrandAdvertiserSearch = "Brand/Advertiser Search",
  OpeningPopUp = "Opening the Pop-Up",
  ClickingWatchVideo = "Clicking to Watch the Video",
  Watching30SecondsOrMore = "Watching 30 Seconds or More",
  Watching80Percent = "Watching 80% or More of the Video",
  ClickingTranscriptButton = "Clicking Transcript Button",
  ViewingTheLandingPage = "Viewing the Landing Page",
  InteractingWithAdSpendVisualData = "Interacting with Ad Spend/Visual Data",
  SharingVideo = "Sharing a Video",
  // SwipingVideo = "Adding video to Swipe File",
  SwipingVideo = "Swiping a Video",
  CategorizingIntoBoards = "Categorizing into Boards",
  UsingSearchInHomePage = "Using search",
  UsingSuggestionsInHomePage = "Using suggestions",
}

const weights = [3, 3, 1, 0.5, 1.5, 1.5, 0.5, 0.5, 0.5, 4, 3, 0.5, 0, 0];

@Injectable({
  providedIn: "root",
})
export class FeedDataService {
  constructor(private apiService: ApiService) {}

  public async AdsSearch(data: any) {
    await this.InsertToDatabase(EventType.AdsSearch, data);
  }

  public async BrandAdvertiserSearch(data: any) {
    await this.InsertToDatabase(EventType.BrandAdvertiserSearch, data);
  }

  public async OpeningPopUp(data: any) {
    await this.InsertToDatabase(EventType.OpeningPopUp, data);
  }

  public async ClickingWatchVideo(data: any) {
    await this.InsertToDatabase(EventType.ClickingWatchVideo, data);
  }

  public async Watching30SecondsOrMore(data: any) {
    await this.InsertToDatabase(EventType.Watching30SecondsOrMore, data);
  }

  public async Watching80Percent(data: any) {
    await this.InsertToDatabase(EventType.Watching80Percent, data);
  }

  public async ClickingTranscriptButton(data: any) {
    await this.InsertToDatabase(EventType.ClickingTranscriptButton, data);
  }

  public async ViewingTheLandingPage(data: any) {
    await this.InsertToDatabase(EventType.ViewingTheLandingPage, data);
  }

  public async InteractingWithAdSpendVisualData(data: any) {
    await this.InsertToDatabase(
      EventType.InteractingWithAdSpendVisualData,
      data
    );
  }

  public async SharingVideo(data: any) {
    await this.InsertToDatabase(EventType.SharingVideo, data);
  }

  public async SwipingVideo(data: any) {
    await this.InsertToDatabase(EventType.SwipingVideo, data);
  }

  public async CategorizingIntoBoards(data: any) {
    await this.InsertToDatabase(EventType.CategorizingIntoBoards, data);
  }

  public async UsingSearchInHomePage(data: any) {
    await this.InsertToDatabase(EventType.UsingSearchInHomePage, {});
  }

  public async UsingSuggestionsInHomePage(data: any) {
    await this.InsertToDatabase(EventType.UsingSuggestionsInHomePage, {});
  }

  private async InsertToDatabase(type: EventType, data: any) {
    const index = this.GetWeightsIndex(type);
    await this.apiService.InsertUserAction({
      ...data,
      weight: weights[index],
      actionName: type,
    });
  }

  private GetWeightsIndex(type: EventType) {
    return Object.values(EventType).indexOf(type);
  }
}
