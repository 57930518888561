import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-like-dislike",
  templateUrl: "./like-dislike.component.html",
})
export class LikeDislikeComponent {
  @Input() isLiked: boolean = false;
  @Input() isDisliked: boolean = false;
  @Output() videoEngaged: EventEmitter<{
    isLiked: boolean;
    isDisliked: boolean;
  }> = new EventEmitter();

  public async SetLikeDislike(like: boolean, dislike: boolean) {
    this.isLiked = like;
    this.isDisliked = dislike;
    this.videoEngaged.emit({ isLiked: like, isDisliked: dislike });
  }
}
