<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="text-center">Rank</th>
                <th>Thumbnail</th>
                <th>Brand</th>
                <th>Category</th>
                <th>Total spend</th>
            </tr>
        </thead>
        <tbody>
            <tr class="list-option" (click)="OpenSelectedBrand(brand)" *ngFor="let brand of brands?.results">
                <td data-label="Rank" class="text-centered">{{brand.rank}}</td>
                <td class="logo-display" data-label="Frame">
                    <div class="ml-2 thumbnail">
                        <img style=" width: 40px; height: 40px; border-radius: 50%;" [src]='brand.thumbnail' />
                    </div>
                </td>
                <td class="company-name" data-label="Brand">
                    {{brand.name}}
                </td>
                <td data-label="Category">
                    {{staticDataService.categoriesMap?.get(brand.categoryId)?.name}}
                </td>
                <td data-label="Total spend">${{brand.spend | valueFormat}}</td>
            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="brands.hasMore" [resultsPerPage]="resultsPerPage" [resultsPerPage]="resultsPerPage"
        [currentPage]="currentPage" (pageSizeChanged)="onPageSizeChange($event)" (pageNumChanged)="ChangePage($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>