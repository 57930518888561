<div class="container-fluid d-flex flex-wrap px-5 mt-5">
    <div class="card-container" *ngFor="let swipe of filteredSwipes;  let i = index">
        <div appInView [elementIndex]="i" (inViewport)="onInViewport($event)" class="swipe-card">
            <div class="d-flex row mx-0 p-2">
                <div class="col-12 p-0">
                    <p class="mb-0 crop">
                        <span class="card-title">Title: </span>
                        <span class="card-text">{{swipe.title}}</span>
                    </p>
                    <p class="mb-0 crop">
                        <span class="card-title">Brand: </span>
                        <span class="card-text">{{swipe.brandName}}</span>
                    </p>
                </div>
            </div>
            <div *ngIf="swipe.isInViewport" class="p-1">
                <app-yt-player-controls class="w-100" [ytVideoId]="swipe.ytVideoId"></app-yt-player-controls>
            </div>
            <iframe *ngIf="!swipe.isInViewport" [src]="''" style="width: 100%; height: 450px" frameborder="0"
                allowfullscreen>
            </iframe>
            <div class="d-flex p-1">
                <app-swipe-board-select (deleteSwipe)="DeleteSwipe(swipe.ytVideoId)" [isAdding]="false"
                    *ngIf="!shareBoardId" style="flex-grow: 1;"
                    (boardsSelected)="BoardsSelected($event,swipe.ytVideoId)"
                    [selectedSwipeBoardIds]="swipe.swipeBoardIds" [isMultiple]="true"
                    [swipeBoards]="swipeBoards"></app-swipe-board-select>
                <div (click)="OpenSelectedSwipe(swipe)"
                    class="p-1 justify-content-center align-items-center d-flex info-click ml-1">
                    <img style="height: 20px; width: 20px;" src="assets/icons/info-circle.png" />
                </div>
            </div>

        </div>
    </div>
</div>