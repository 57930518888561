import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiResponse } from "../../models/apiResponse";
import { EntityStats } from "src/app/models/entityStats";
import { Brand } from "src/app/models/brand";
import { GetCompanyResponse } from "src/app/models/getCompanyResponse";
import { GetBrandResponse } from "src/app/models/getBrandResponse";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { Video } from "src/app/models/video";
import { ChartData } from "src/app/models/chartData";
import { environment } from "src/environments/environment";
import { VideoCreativeStats } from "src/app/models/videosCreativeStats";
import { GetCategoriesResponse } from "src/app/models/getCategoriesResponse";
import { CompanyRanking } from "src/app/models/companyRanking";
import { BrandRanking } from "src/app/models/brandRanking";
import { NonVideoAd } from "src/app/models/nonVideoAd";
import { VideoChartData } from "src/app/models/videoChartData";
import firebase from "firebase/compat/app";
import { BillingUser } from "src/app/models/billingUser";
import { SubscriptionPlan } from "src/app/models/subscriptionPlan";
import { Category } from "src/app/models/category";
import { Country } from "src/app/models/country";
import { Company } from "src/app/models/company";
import { Ranks } from "src/app/models/ranks";
import { AffiliateNetwork } from "src/app/models/affiliateNetwork";
import { AffiliateOffer } from "src/app/models/affiliateOffer";
import { GetOfferResponse } from "src/app/models/getOfferResponse";
import { Training } from "src/app/models/training";
import { PaymentPlan } from "src/app/models/paymentPlan";
import { RecentSearch } from "src/app/models/recentSearch";
import { GetPaymentPlans } from "src/app/models/getPaymentPlans";
import { User } from "src/app/models/user";
import {
  brandCompaniesDummyData,
  companyBrandsDummyData,
  performanceDummydata,
  successDistributionDummydata,
  testingDummydata,
} from "src/app/utils/dummy-data";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { SharedBoardUser } from "src/app/models/sharedBoardUser";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  root: string = environment.apiUrl;

  categoriesMap = new Map<number, Category>();
  countriesMap = new Map<number, Country>();
  constructor(private httpClient: HttpClient) {}

  //Companies api
  public async QuickSearchCompanies(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/companies/quickSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async FullSearchCompanies(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/companies/fullSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async LoadBrandsForCompany(
    companyId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(companyBrandsDummyData);

    return await this.httpClient
      .get<ApiResponse<SearchResponse<Brand>>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/brands?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadCompanyById(
    companyId: number,
    optionalUrl: string,
    encryptedPayload: string,
    basicInfo?: boolean
  ) {
    return await this.httpClient
      .get<ApiResponse<GetCompanyResponse>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}?` +
          `basicInfo=${basicInfo}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async PreviewVideoAds(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/videos/quickSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async FullSearchVideoAds(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/videos/search`,
        searchRequest
      )
      .toPromise();
  }
  public async FullSearchAffiliateVideoAds(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/videos/searchAffiliate`,
        searchRequest
      )
      .toPromise();
  }

  public async LoadCompaniesVideos(
    companyId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    optionalUrl: string,
    encryptedId: string,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/videos?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&keyword=${keyword}&encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  public async LoadCompaniesShorts(
    companyId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/companies/${companyId}/shorts?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&keyword=${keyword}`
      )
      .toPromise();
  }

  public async LoadCompanyStats(companyId: number) {
    return await this.httpClient
      .get<ApiResponse<EntityStats>>(
        `${this.root}/api/companies/${companyId}/spend`
      )
      .toPromise();
  }

  public async LoadCompanyRanks(companyId: number) {
    return await this.httpClient
      .get<ApiResponse<Ranks>>(`${this.root}/api/companies/${companyId}/ranks`)
      .toPromise();
  }

  public async LoadCompanyPerformanceStats(
    companyId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(performanceDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/performanceChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async FetchFakeData<T>(data: T): Promise<T> {
    return new Promise<T>((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, 100);
    });
  }

  public async LoadCompanyTestingStats(
    companyId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(testingDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/testingChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadTopCompanies(
    categoryId: number,
    countryId: number,
    timeFrame: string,
    page: number,
    limit: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<CompanyRanking>>>(
        `${this.root}/api/companies/top?` +
          `category=${categoryId}&country=${countryId}&timeFrame=${timeFrame}&page=${page}&limit=${limit}`
      )
      .toPromise();
  }

  //Brands api
  public PreviewBrands(searchRequest: SearchRequest) {
    return this.httpClient.post<ApiResponse<SearchResponse<Brand>>>(
      `${this.root}/api/brands/quickSearch`,
      searchRequest
    );
  }

  public async QuickSearchBrands(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Brand>>>(
        `${this.root}/api/brands/quickSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async FullSearchBrands(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Brand>>>(
        `${this.root}/api/brands/fullSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async LoadCompaniesForBrand(
    brandId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedId: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(brandCompaniesDummyData);

    return await this.httpClient
      .get<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/companies?` +
          `encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  public async LoadBrandById(
    brandId: number,
    optionalUrl: string,
    encryptedPayload: string,
    basicInfo?: boolean
  ) {
    return await this.httpClient
      .get<ApiResponse<GetBrandResponse>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}?` +
          `basicInfo=${basicInfo}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadBrandVideos(
    brandId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    optionalUrl: string,
    encryptedId: string,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/videos?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&keyword=${keyword}&encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  public async LoadBrandShorts(
    brandId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/brands/${brandId}/shorts?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&keyword=${keyword}`
      )
      .toPromise();
  }

  public async LoadBrandRanks(brandId: number) {
    return await this.httpClient
      .get<ApiResponse<Ranks>>(`${this.root}/api/brands/${brandId}/ranks`)
      .toPromise();
  }

  public async LoadBrandStats(brandId: number) {
    return await this.httpClient
      .get<ApiResponse<EntityStats>>(`${this.root}/api/brands/${brandId}/spend`)
      .toPromise();
  }

  public async LoadBrandPerformanceStats(
    brandId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(performanceDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/performanceChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadBrandTestingStats(
    brandId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(testingDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/testingChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadTopBrands(
    categoryId: number,
    countryId: number,
    timeFrame: string,
    page: number,
    limit: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<BrandRanking>>>(
        `${this.root}/api/brands/top?` +
          `category=${categoryId}&country=${countryId}&timeFrame=${timeFrame}&page=${page}&limit=${limit}`
      )
      .toPromise();
  }

  //Videos api
  public async GetVideoInfo(
    ytVideoIt: string,
    optionalUrl: string,
    encryptedId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<Video>>(
        `${this.root}/api/videos${optionalUrl}/${ytVideoIt}?` +
          `encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  public async GetVideoStatistics(
    ytVideoIt: string,
    optionalUrl: string,
    encryptedId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<VideoCreativeStats>>(
        `${this.root}/api/videos${optionalUrl}/${ytVideoIt}/creative?` +
          `encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  //Trainings api
  public async GetTrainings() {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/trainings`)
      .toPromise();
  }
  public async GetYtTrainingInfo(trainingId: string) {
    return await this.httpClient
      .get<ApiResponse<Training>>(`${this.root}/api/trainings/${trainingId}`)
      .toPromise();
  }

  public async GetVexpowerTrainingInfo(slug: string) {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/trainings/vexpower/${slug}`)
      .toPromise();
  }

  public async GetCategories() {
    return await this.httpClient
      .get<ApiResponse<GetCategoriesResponse>>(
        `${this.root}/api/categories/nested`
      )
      .toPromise();
  }

  // Display and text ads for company

  public async LoadCompaniesTextAds(
    companyId: number,
    page: number,
    limit: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<NonVideoAd>>>(
        `${this.root}/api/companies/${companyId}/textAds?` +
          `page=${page}&limit=${limit}`
      )
      .toPromise();
  }

  public async LoadCompaniesDisplayAds(
    companyId: number,
    page: number,
    limit: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<NonVideoAd>>>(
        `${this.root}/api/companies/${companyId}/displayAds?` +
          `page=${page}&limit=${limit}`
      )
      .toPromise();
  }

  //Success distribution chart

  public async LoadCompanyDistributionSuccess(
    companyId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser)
      return await this.FetchFakeData(successDistributionDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/successDistributionChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadBrandDistributionSuccess(
    brandId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser)
      return await this.FetchFakeData(successDistributionDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/successDistributionChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadOfferDistributionSuccess(
    offerId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser)
      return await this.FetchFakeData(successDistributionDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/successDistributionChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  //Evolution of winners chart

  public async LoadCompanyEvolutionOfWinners(
    companyId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    // if (!isPayingUser)
    //   return await this.FetchFakeData(videoTimelineDummyData);

    return await this.httpClient
      .get<ApiResponse<VideoChartData>>(
        `${this.root}/api/companies${optionalUrl}/${companyId}/videoTimelineChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadBrandEvolutionOfWinners(
    brandId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    // if (!isPayingUser)
    //   return await this.FetchFakeData(videoTimelineDummyData);

    return await this.httpClient
      .get<ApiResponse<VideoChartData>>(
        `${this.root}/api/brands${optionalUrl}/${brandId}/videoTimelineChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadOfferEvolutionOfWinners(
    offerId: number,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    // if (!isPayingUser)
    //   return await this.FetchFakeData(videoTimelineDummyData);

    return await this.httpClient
      .get<ApiResponse<VideoChartData>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/videoTimelineChart?` +
          `encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  //static data loading

  public async GetAllCategories() {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/categories`)
      .toPromise();
  }

  public async GetAllOffers() {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<AffiliateOffer>>>(
        `${this.root}/api/shared/offer/getAll`
      )
      .toPromise();
  }

  public async GetAllCountries() {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/countries`)
      .toPromise();
  }

  //user
  public async GetUser() {
    return await this.httpClient
      .get<ApiResponse<User>>(`${this.root}/api/users?`)
      .toPromise();
  }

  public async UpdateUser(name: string) {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users?`, {
        name: name,
      })
      .toPromise();
  }

  public async RemoveStrategySessionBanner() {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users/removeBanner`, {})
      .toPromise();
  }

  //Billing and payments

  public async GetBillingInfo(user: firebase.User) {
    return await this.httpClient
      .get<ApiResponse<BillingUser>>(
        `${this.root}/api/payments/billing?` + `user=${user}`
      )
      .toPromise();
  }

  public async UpdateBillingInfo(
    user: firebase.User,
    billingInfo: BillingUser
  ) {
    return await this.httpClient
      .post<ApiResponse<BillingUser>>(
        `${this.root}/api/payments/billing?` + `user=${user}`,
        billingInfo
      )
      .toPromise();
  }

  public async GetAllPayments(user: firebase.User) {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/payments?` + `user=${user}`)
      .toPromise();
  }

  public async GetAllSubscriptionPlans(user: firebase.User) {
    return await this.httpClient
      .get<ApiResponse<SubscriptionPlan[]>>(
        `${this.root}/api/payments/availablePlans?` + `user=${user}`
      )
      .toPromise();
  }

  public async SwipeCompany(companyId: number, swipeBoardIds?: number[]) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/companies/swipe`, {
        companyId,
        swipeBoardIds,
      })
      .toPromise();
  }
  public async RemoveSwipedCompany(companyId: number) {
    return await this.httpClient
      .delete<ApiResponse<any>>(`${this.root}/api/companies/swipe/${companyId}`)
      .toPromise();
  }

  public async SwipeBrand(brandId: number, swipeBoardIds?: number[]) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/brands/swipe`, {
        brandId,
        swipeBoardIds,
      })
      .toPromise();
  }

  public async RemoveSwipedBrand(brandId: number) {
    return await this.httpClient
      .delete<ApiResponse<any>>(`${this.root}/api/brands/swipe/${brandId}`)
      .toPromise();
  }

  public async SwipeVideo(ytVideoId: string, swipeBoardIds?: number[]) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/videos/swipe`, {
        ytVideoId,
        swipeBoardIds,
      })
      .toPromise();
  }
  public async RemoveSwipedVideo(ytVideoId: string) {
    return await this.httpClient
      .delete<ApiResponse<any>>(`${this.root}/api/videos/swipe/${ytVideoId}`)
      .toPromise();
  }

  public async GetSwipedCompanies(
    page: number,
    limit: number,
    searchTerm: string,
    sortProp: string,
    orderAsc: boolean,
    isAffiliate: boolean,
    swipeBoardId: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/companies/swipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&sortProp=${sortProp}&orderAsc=${orderAsc}&isAffiliate=${isAffiliate}&swipeBoardId=${swipeBoardId}`
      )
      .toPromise();
  }

  public async GetSharedBoardCompanies(
    page: number,
    limit: number,
    searchTerm: string,
    sortProp: string,
    orderAsc: boolean,
    isAffiliate: boolean,
    shareId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/swipeBoards/companySwipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&sortProp=${sortProp}&orderAsc=${orderAsc}&isAffiliate=${isAffiliate}&shareId=${shareId}`
      )
      .toPromise();
  }

  public async GetSwipedBrands(
    page: number,
    limit: number,
    searchTerm: string,
    sortProp: string,
    orderAsc: boolean,
    swipeBoardId: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Brand>>>(
        `${this.root}/api/brands/swipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&sortProp=${sortProp}&orderAsc=${orderAsc}&swipeBoardId=${swipeBoardId}`
      )
      .toPromise();
  }
  public async GetSharedBoardBrands(
    page: number,
    limit: number,
    searchTerm: string,
    sortProp: string,
    orderAsc: boolean,
    shareId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Brand>>>(
        `${this.root}/api/swipeBoards/brandSwipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&sortProp=${sortProp}&orderAsc=${orderAsc}&shareId=${shareId}`
      )
      .toPromise();
  }

  public async GetSwipedVideos(
    isAffiliate: boolean,
    searchTerm: string,
    swipeBoardId?: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/videos/swipes?` +
          `isAffiliate=${isAffiliate}&searchTerm=${searchTerm}&swipeBoardId=${swipeBoardId}&limit=500`
      )
      .toPromise();
  }

  public async GetSharedBoardVideos(
    isAffiliate: boolean,
    searchTerm: string,
    shareId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/swipeBoards/videoSwipes?` +
          `isAffiliate=${isAffiliate}&searchTerm=${searchTerm}&shareId=${shareId}&limit=500`
      )
      .toPromise();
  }

  //Affiliate network

  public async GetAffiliateNetworks() {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<AffiliateNetwork>>>(
        `${this.root}/api/affiliate/allNetworks?`
      )
      .toPromise();
  }

  public async FullSearchOffers(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<AffiliateOffer>>>(
        `${this.root}/api/affiliate/offers`,
        searchRequest
      )
      .toPromise();
  }

  //Offer
  public async LoadOfferById(
    offerId: number,
    optionalUrl: string,
    encryptedPayload: string,
    basicInfo?: boolean
  ) {
    return await this.httpClient
      .get<ApiResponse<GetOfferResponse>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}?` +
          `basicInfo=${basicInfo}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadOfferPerformanceStats(
    offerId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(performanceDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/performanceChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadOfferTestingStats(
    offerId: number,
    view: string,
    period: string,
    isPayingUser: boolean,
    optionalUrl: string,
    encryptedPayload: string
  ) {
    if (!isPayingUser) return await this.FetchFakeData(testingDummydata);

    return await this.httpClient
      .get<ApiResponse<ChartData>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/testingChart?` +
          `view=${view}&period=${period}&encryptedId=${encryptedPayload}`
      )
      .toPromise();
  }

  public async LoadOfferVideos(
    offerId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    optionalUrl: string,
    encryptedId: string,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/videos?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&encryptedId=${encryptedId}&keyword=${keyword}`
      )
      .toPromise();
  }

  public async LoadOfferShorts(
    offerId: number,
    page: number,
    limit: number,
    sort: string,
    orderAsc: boolean,
    keyword: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Video>>>(
        `${this.root}/api/affiliate/offer/${offerId}/shorts?` +
          `page=${page}&limit=${limit}&sort=${sort}&orderAsc=${orderAsc}&keyword=${keyword}`
      )
      .toPromise();
  }

  public async SwipeOffer(offerId: number, swipeBoardIds?: number[]) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/affiliate/swipe`, {
        offerId,
        swipeBoardIds,
      })
      .toPromise();
  }

  public async RemoveSwipedOffer(offerId: number) {
    return await this.httpClient
      .delete<ApiResponse<any>>(`${this.root}/api/affiliate/swipe/${offerId}`)
      .toPromise();
  }

  public async LoadOfferSwipes(
    searchTerm: string,
    page: number,
    limit: number,
    swipeBoardId?: number
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<AffiliateOffer>>>(
        `${this.root}/api/affiliate/swipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&swipeBoardId=${swipeBoardId}`
      )
      .toPromise();
  }

  public async GetSharedBoardOffers(
    searchTerm: string,
    page: number,
    limit: number,
    shareId?: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<AffiliateOffer>>>(
        `${this.root}/api/swipeBoards/offerSwipes?` +
          `page=${page}&limit=${limit}&searchTerm=${searchTerm}&shareId=${shareId}`
      )
      .toPromise();
  }

  public async LoadCompaniesForOffer(
    offerId: number,
    optionalUrl: string,
    encryptedId: string
  ) {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/affiliate${optionalUrl}/offer/${offerId}/companies?` +
          `&encryptedId=${encryptedId}`
      )
      .toPromise();
  }

  public async LoadAffiliateCompanies(searchRequest: SearchRequest) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<Company>>>(
        `${this.root}/api/companies/affiliateSearch`,
        searchRequest
      )
      .toPromise();
  }

  public async GetCurrentPlan() {
    return await this.httpClient
      .get<ApiResponse<PaymentPlan>>(`${this.root}/api/payments/currentPlan`)
      .toPromise();
  }

  public async GetTrialPlan() {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/payments/freeTrial`)
      .toPromise();
  }

  public async UpgradeToYearly() {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/payments/upgrade`, {})
      .toPromise();
  }

  public async GetAvailablePlans() {
    return await this.httpClient
      .get<ApiResponse<GetPaymentPlans>>(
        `${this.root}/api/payments/availablePlans`
      )
      .toPromise();
  }

  public async GetRecentSearches() {
    return await this.httpClient
      .get<ApiResponse<SearchResponse<RecentSearch>>>(
        `${this.root}/api/recents/pageVisits`
      )
      .toPromise();
  }

  //Newsletter

  public async GetNewslettersStatus() {
    return await this.httpClient
      .get<ApiResponse<any>>(`${this.root}/api/users/getNewsletter?`)
      .toPromise();
  }

  public async SubscribeToNewsletters() {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/users/subscribeNewsletter`, {})
      .toPromise();
  }

  public async UnsubscribeFromNewsletters() {
    return await this.httpClient
      .post<ApiResponse<any>>(
        `${this.root}/api/users/unsubscribeNewsletter`,
        {}
      )
      .toPromise();
  }

  public async SubscribeUser(event: any) {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users/checkSubscription`, {
        event,
      })
      .toPromise();
  }

  public async StartFreeTrial() {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/users/startFreeTrial`, {})
      .toPromise();
  }

  public async SendContactEmailMessage(
    firstName: string,
    email: string,
    message: string
  ) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/contact/support`, {
        name: firstName,
        email: email,
        message: message,
      })
      .toPromise();
  }

  public async GetSwipeBoards() {
    return await this.httpClient
      .get<ApiResponse<SwipeBoard[]>>(`${this.root}/api/swipeBoards/all`)
      .toPromise();
  }

  public async UpsertSwipeBoard(name: string, swipeBoardId?: number) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<RecentSearch>>>(
        `${this.root}/api/swipeBoards/upsert`,
        { name: name, swipeBoardId: swipeBoardId }
      )
      .toPromise();
  }

  public async UnsubscribeFromSharedBoard(swipeBoardId?: number) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<RecentSearch>>>(
        `${this.root}/api/swipeBoards/removeFromShared`,
        { swipeBoardId }
      )
      .toPromise();
  }

  public async DeleteSwipeBoard(swipeBoardId: number) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<RecentSearch>>>(
        `${this.root}/api/swipeBoards/delete`,
        { swipeBoardId: swipeBoardId }
      )
      .toPromise();
  }

  public async GetSharedBoardUser(shareId: string) {
    return await this.httpClient
      .get<ApiResponse<SharedBoardUser>>(
        `${this.root}/api/swipeBoards/shared/${shareId}`
      )
      .toPromise();
  }

  public async AcceptSharedBoard(shareId: string) {
    return await this.httpClient
      .post<ApiResponse<SharedBoardUser>>(
        `${this.root}/api/swipeBoards/acceptSharedBoard`,
        { shareId }
      )
      .toPromise();
  }

  // Crypting and decrypting

  public async GetKey(data: object) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/shared/createKey`, {
        data,
      })
      .toPromise();
  }

  public async ResolveKey(key: string) {
    return await this.httpClient
      .post<ApiResponse<any>>(`${this.root}/api/shared/resolveKey`, { key })
      .toPromise();
  }

  public async InsertResponse(
    email: string,
    name: string,
    description: string,
    adPlatforms: string,
    biggestChallenge: string,
    adSpend: string,
    bussinessWebsite: string,
    advertisingPages: string
  ) {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users/response`, {
        email,
        name,
        description,
        adPlatforms,
        biggestChallenge,
        adSpend,
        bussinessWebsite,
        advertisingPages,
      })
      .toPromise();
  }

  public async GetVideoTranscript(ytVideoId: string) {
    return await this.httpClient
      .get<ApiResponse<any>>(
        `${this.root}/api/videos/getTranscript/${ytVideoId}`
      )
      .toPromise();
  }

  public async GetNetworkAdsCount(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<any>>(
        `${this.root}/api/affiliate/networks/stats/adsCount`,
        { id: networkId }
      )
      .toPromise();
  }

  public async GetNetworkActiveAdsCount(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<any>>(
        `${this.root}/api/affiliate/networks/stats/activeAdsCount`,
        { id: networkId }
      )
      .toPromise();
  }

  public async GetNetworkOffersCount(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<any>>(
        `${this.root}/api/affiliate/networks/stats/offersCount`,
        { id: networkId }
      )
      .toPromise();
  }

  public async GetNetworkActiveAffiliatesCount(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<any>>(
        `${this.root}/api/affiliate/networks/stats/activeAffiliateCount`,
        { id: networkId }
      )
      .toPromise();
  }

  public async GetNetworkTopOffers(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<AffiliateOffer>>>(
        `${this.root}/api/affiliate/networks/topOffers`,
        { id: networkId }
      )
      .toPromise();
  }

  public async GetNetworkTopLanguages(networkId: number) {
    return await this.httpClient
      .post<ApiResponse<SearchResponse<any>>>(
        `${this.root}/api/affiliate/networks/topLanguages`,
        { id: networkId }
      )
      .toPromise();
  }

  public async UpdateUserTutorials(body: any) {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users/updateTutorials`, body)
      .toPromise();
  }

  public async InsertUserAction(body: any) {
    return await this.httpClient
      .post<ApiResponse<User>>(`${this.root}/api/users/insertUserAction`, body)
      .toPromise();
  }

  public async GetUserFeed() {
    return await this.httpClient
      .get<any>(`${this.root}/api/users/getUserFeed`)
      .toPromise();
  }

  public async EngageOnVideo(body: any) {
    return await this.httpClient
      .post<any>(`${this.root}/api/users/engageVideo`, body)
      .toPromise();
  }
}
