import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  ActivatedRoute,
  NavigationExtras,
  Route,
  Router,
} from "@angular/router";
import { EntityStats } from "src/app/models/entityStats";
import { ModuleType } from "src/app/models/enums";
import { GetCompanyResponse } from "src/app/models/getCompanyResponse";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-preview-company-info",
  templateUrl: "./preview-company-info.component.html",
  styleUrls: ["../entity-info.component.scss"],
})
export class PreviewCompanyInfoComponent implements OnInit {
  companyResponse: GetCompanyResponse = new GetCompanyResponse();
  entityStats: EntityStats = new EntityStats();
  basicInfo: boolean = true;
  @Input() companyId: number = 0;
  @Input() currentRoute: string = "";
  @Input() isPayingUser: boolean = true;
  @Input() isAffiliate: boolean = false;
  swipeBoards: SwipeBoard[] = [];
  shareUrl: string = "";

  @Output() dialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private router: Router,
    public stateService: StateService,
    private popupService: PopupService,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<PreviewCompanyInfoComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    Promise.all([
      this.LoadSwipeBoards(),
      this.LoadCompanyInfo(),
      this.LoadCompanyStats(),
    ]);
    this.CreateShareUrl();
  }

  private async LoadCompanyInfo() {
    const result = await this.apiService.LoadCompanyById(
      this.companyId,
      "",
      "",
      this.basicInfo
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.companyResponse = result.data;
    }
  }

  private async LoadCompanyStats() {
    const result = await this.apiService.LoadCompanyStats(this.companyId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.entityStats = result.data;
    }
  }

  public OpenSelectedCompany() {
    let isAffiliate = this.isAffiliate;
    this.dialogClosed.emit(true);
    const queryParams: NavigationExtras = {
      queryParams: {
        companyId: this.companyId,
        keyword: "",
        ...(isAffiliate && { isAffiliate }),
      },
      //replaceUrl: true,
      relativeTo: this.activatedRoute,
    };

    //if (currentRoute == '')
    this.router.navigate(["dashboard/entity-info"], queryParams);
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public get ShareUrl() {
    return this.shareUrl;
  }

  public async CreateShareUrl() {
    if (this.companyId != 0)
      this.shareUrl = await this.GetShareUrl({
        companyId: this.companyId,
      });
  }

  public async GetShareUrl(data: object) {
    const key = await this.CreateKey(data);
    // return window.location.origin + "/#/shared/" + key + "/entity-info";
    return `${window.location.origin}/#/shared/${encodeURIComponent(
      key
    )}/entity-info`;
  }

  private async CreateKey(data: object) {
    const result = await this.apiService.GetKey(data);
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) return result?.data;
  }

  public async CompanySwipeSaved(swipeBoardIds: number[]) {
    const result = await this.apiService.SwipeCompany(
      this.companyId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(
      `${this.companyResponse.company.legalName} successfuly saved to favorites!`
    );
    this.companyResponse.company.swiped = true;
  }

  public RemoveCompanySwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected company?",
      async () => await this.CompanySwipeRemoved()
    );
  }

  private async CompanySwipeRemoved() {
    const result = await this.apiService.RemoveSwipedCompany(this.companyId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Company successfuly removed from Swipes.");
    this.companyResponse.company.swiped = false;
  }

  public CloseModal() {
    this.dialogRef.close();
  }
}
