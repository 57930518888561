import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject, from, of } from "rxjs";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  catchError,
  filter,
  shareReplay,
  switchMap,
  take,
} from "rxjs/operators";
import firebase from "firebase/compat/app";
import { GoogleAuthProvider } from "firebase/auth";
import { ApiService } from "../api/api.service";
import { ApiResponse } from "src/app/models/apiResponse";
import { TokenService } from "./token.service";
import { ModuleType } from "src/app/models/enums";
import { User } from "src/app/models/user";
import { PopupService } from "./popup.service";
import { UserSignedUpGtm } from "src/app/utils/gtm";
import { environment } from "src/environments/environment";
import { RudderStackService } from "./rudderstack.service";
import { AnalyticsService } from "./analytics.service";

@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private popupService: PopupService,
    public rudderStackService: RudderStackService,
    public analyticsService: AnalyticsService,
    public router: Router,
    private angularFireAuth: AngularFireAuth
  ) {
    // this.SubscribeUser();
    // this.LoadInitialModule();
    this.Initialize(); //With this we ensure that router is fully initialized before continuing
  }

  private Initialize() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1) // take the first and only navigation start event
      )
      .subscribe(() => {
        this.SubscribeUser();
        this.LoadInitialModule();
      });
  }

  user!: User | null | undefined;
  userSubject = new ReplaySubject<User | null | undefined>(1);
  user$ = this.userSubject.asObservable();

  // userSubject = new Subject<User | null | undefined>();
  // user$ = this.userSubject.asObservable().pipe(shareReplay(1));

  firebaseUser!: firebase.User;

  moduleType: ModuleType = ModuleType.Marketer;
  redirectUrl: string | null = null;
  verificationRedirectUrl: string = "";
  selectedTabSelected$: BehaviorSubject<string> = new BehaviorSubject("");
  selectedModuleChanged$: BehaviorSubject<string> = new BehaviorSubject("");
  isFirstLogin: boolean = false;
  goToDashboard: boolean = false;
  openRegisterModal: boolean = false;
  openSubscriptionModal: boolean = false;
  sharedUserType: string | null = null;

  public TabSelected(tabName: string) {
    this.selectedTabSelected$.next(tabName);
  }

  public ModuleSelected(moduleName: ModuleType) {
    this.moduleType = moduleName;
    this.selectedModuleChanged$.next(moduleName);
  }

  public UpdateUser(user: User | null | undefined): void {
    this.userSubject.next(user);
  }

  private LoadInitialModule() {
    if (sessionStorage.getItem("moduleType") == ModuleType.Affiliate)
      this.moduleType = ModuleType.Affiliate;
    else this.moduleType = ModuleType.Marketer;
  }

  public async SubscribeUser() {
    this.angularFireAuth.authState //only triggered on sign-in / sign-out
      .pipe(
        switchMap((user) => {
          if (user) {
            //if logging in...
            this.tokenService.fbUser = user;
            this.firebaseUser = user;
            return this.apiService.GetUser();
          } else {
            // if logging out or user not logged in at the startup of application...
            if (
              !window.location.toString().includes("reset-password") &&
              !window.location.toString().includes("privacy-policy") &&
              !window.location.toString().includes("terms-of-service") &&
              !window.location.toString().includes("shared")
            ) {
              this.router.navigate(["/home"]);
            }
            return of(null);
          }
        })
      )
      .subscribe((user) => {
        //if user is logging out
        if (user == null) this.user = null;
        //if user successfuly returned from database
        if (user?.data) this.user = user?.data;
        //if user not returned from database(firebase function for create user didnt finish on time), we create default free user
        else if (user?.errorMessage) this.user = this.GetDefaultFreeUser();

        this.userSubject.next(this.user); // Update the all user listeners
        if (this.user) {
          if (this.goToDashboard) this.router.navigateByUrl("/dashboard");
          else if (
            //we dont do any redirection in case user is is already on dashboard subdomain or in shared(module that allows everyone to see pages for a short period of time)
            !this.router.url.includes("dashboard") &&
            !this.router.url.includes("shared")
          )
            this.router.navigateByUrl(
              this.redirectUrl ? this.redirectUrl : "/dashboard"
            );

          this.redirectUrl = null;
          this.InitializeTawk(this.user.email, this.user.name);
          this.rudderStackService.Identify(this.user.userId, this.user.email);
          this.analyticsService.Identify(this.user.userId, this.user.email);
        }
      });
  }

  public GetDefaultFreeUser = () => ({
    email: this.firebaseUser.email ? this.firebaseUser.email : "",
    plan: "free",
    isFreeTrialUsed: false,
    role: "user",
    pastDue: false,
    showStrategySessionBanner: false,
    userId: this.firebaseUser.uid,
    name: this.firebaseUser.displayName ? this.firebaseUser.displayName : "",
    createdAt: new Date(),
    expiresAt: new Date(),
    hasWatchedAffiliate: false,
    hasWatchedAds: false,
    hasWatchedBrands: false,
    hasWatchedSearch: false,
    hasWatchedEntity: false,
  });

  public IsPayingUserState(user: User | undefined | null) {
    return user?.plan === "freeTrial" ||
      user?.plan === "monthly" ||
      user?.plan === "yearly" ||
      user?.plan === "inceptly" ||
      user?.plan === "paid"
      ? true
      : false;
  }
  public IsTrialAlreadyUsed(user: User | undefined | null) {
    return user?.isFreeTrialUsed == true;
  }

  public IsTrialUser(user: User | undefined | null) {
    return user?.plan === "freeTrial" ? true : false;
  }

  public GetExpiryDate(user: User | undefined | null) {
    return user?.expiresAt;
  }

  public IsYearlyUser(user: User | undefined | null) {
    return user?.plan === "yearly" ? true : false;
  }

  public IsInceptlyUser(user: User | undefined | null) {
    return user?.plan === "inceptly" ? true : false;
  }

  public IsPrivilegedUser(user: User | undefined | null) {
    return user?.plan === "inceptly" || user?.plan === "yearly" ? true : false;
  }

  public IsUserLoggedIn() {
    return this.user ? true : false;
  }

  public SignInEmailAndPassword(
    email: string,
    password: string,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
    closeModal: Function
  ) {
    this.VerifyRecatpcha(recaptchaVerifier)
      .pipe(
        switchMap(async () => {
          try {
            const res = await this.angularFireAuth.signInWithEmailAndPassword(
              email,
              password
            );
            closeModal();
          } catch (error) {
            this.popupService.Notify(
              "Account with provided information is not found."
            );
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  public async SignUpEmailAndPassword(
    email: string,
    password: string,
    fullName: string,
    closeModal: Function
  ) {
    try {
      const res = await this.angularFireAuth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (res.additionalUserInfo?.isNewUser) {
        this.isFirstLogin = res.additionalUserInfo?.isNewUser;
        UserSignedUpGtm();
        this.rudderStackService.UserRegistered(
          res.user?.uid,
          email,
          this.sharedUserType
        );
        this.analyticsService.UserRegistered(
          res.user?.uid,
          email,
          this.sharedUserType
        );
        this.SendVerificationEmail();
      }
      await this.angularFireAuth.signInWithEmailAndPassword(email, password);
      closeModal();
      this.apiService.UpdateUser(fullName);
    } catch (error) {
      this.popupService.Notify("Error while trying to sign up.");
    }
    catchError(() => {
      return of(null);
    });
  }

  public SignInWithGoogle(
    recaptchaVerifier: firebase.auth.RecaptchaVerifier,
    closeModal: Function
  ) {
    this.VerifyRecatpcha(recaptchaVerifier)
      .pipe(
        switchMap(async () => {
          try {
            const res = await this.angularFireAuth.signInWithPopup(
              new GoogleAuthProvider()
            );
            if (res.additionalUserInfo?.isNewUser) {
              UserSignedUpGtm();
              this.rudderStackService.UserRegistered(
                res.user?.uid,
                res.user?.email,
                this.sharedUserType
              );
              this.analyticsService.UserRegistered(
                res.user?.uid,
                res.user?.email,
                this.sharedUserType
              );
              this.isFirstLogin = res.additionalUserInfo?.isNewUser;
            }
            closeModal();
          } catch (error) {
            this.popupService.Notify("Error while trying to sign up.");
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }
  public SendPasswordResetEmail(email: string, url: string) {
    this.angularFireAuth.sendPasswordResetEmail(email, { url });
  }
  public ConfirmPasswordReset(code: string, email: string) {
    this.angularFireAuth.confirmPasswordReset(code, email);
  }
  public SendVerificationEmail() {
    var actionCodeSettings = {
      url: environment.domain + this.verificationRedirectUrl,
      handleCodeInApp: true,
    };
    this.angularFireAuth.currentUser.then((user) => {
      user?.sendEmailVerification(actionCodeSettings);
    });
  }
  private VerifyRecatpcha(recaptchaVerifier: firebase.auth.RecaptchaVerifier) {
    return from(recaptchaVerifier.verify());
  }
  public async SignOut() {
    await this.angularFireAuth.signOut();
  }
  public InitializeTawk(email: string, name: string) {
    //@ts-ignore
    window.tawkCall({
      email: email,
      name: name,
    });
  }
  //static data
}
