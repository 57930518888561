import { Component, Input } from "@angular/core";
import { FeedItem } from "src/app/models/feedItem";
import { StaticDataService } from "src/app/services/shared/static.data.service";
@Component({
  selector: "app-feed",
  templateUrl: "./feed.component.html",
  styleUrl: "./feed.component.scss",
})
export class FeedComponent {
  constructor(public staticDataService: StaticDataService) {}
  @Input() feedData: FeedItem[] = [];
  swiperBreakpoints = {
    100: {
      slidesPerView: 1,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 1.5,
    },
  };

  public onInViewport(event: { index: number; isInViewport: boolean }): void {
    this.feedData[event.index].isInViewport = event.isInViewport;
  }
}
