import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { combineLatest, fromEvent } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { SearchSuggestionsComponent } from "src/app/components/search-suggestions/search-suggestions.component";
import { ChartData } from "src/app/models/chartData";
import { DEBOUNCE_TIME, DEBOUNCE_TIME2 } from "src/app/models/consts";
import {
  ChartPerformancePeriod,
  ChartPerformanceType,
  ChartTestingPeriod,
  ChartTestingType,
  DisplayType,
  ModuleType,
  SearchType,
} from "src/app/models/enums";
import { GetCompanyResponse } from "src/app/models/getCompanyResponse";
import { GetBrandResponse } from "src/app/models/getBrandResponse";
import { Info } from "src/app/models/info";
import { VideoRanking } from "src/app/models/videoRanking";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { GetOfferResponse } from "src/app/models/getOfferResponse";
import { ScreenshotService } from "src/app/services/shared/screenshot.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-entity-info",
  templateUrl: "./entity-info.component.html",
  styleUrls: ["./entity-info.component.scss"],
})
export class EntityInfoComponent implements OnInit, AfterViewInit {
  @ViewChild("Input") overlayInput!: ElementRef;
  companyId: number = 0;
  brandId: number = 0;
  offerId: number = 0;

  searchType = SearchType;
  type: SearchType = SearchType.Companies;
  typeSearch: SearchType = SearchType.Companies;

  displayType = DisplayType;
  dtype: DisplayType = DisplayType.Youtube;
  isAffiliate: boolean = false;

  //#region performance chart
  chartPerformanceType = ChartPerformanceType;
  chartPerformancePeriod = ChartPerformancePeriod;
  xValuesPerformance: number[] = [];
  yValuesPerformance: string[] = [];
  chartTypePerformance: string = ChartPerformanceType.Spends;
  chartPeriodPerformance: string = ChartPerformancePeriod.Year;
  //#endregion

  //#region testing chart
  chartTestingType = ChartTestingType;
  chartTestingPeriod = ChartTestingPeriod;
  xValuesTesting: number[] = [];
  yValuesTesting: string[] = [];
  chartTypeTesting: string = ChartTestingType.Spend;
  chartPeriodTesting: string = ChartTestingPeriod.Year;
  //#endregion

  distributionSuccessChartData: ChartData = new ChartData();

  companyResponse: GetCompanyResponse = new GetCompanyResponse();
  brandResponse: GetBrandResponse = new GetBrandResponse();
  offerResponse: GetOfferResponse = new GetOfferResponse();

  //entityStats: EntityStats = new EntityStats();
  swInfo: Info = new Info();

  sortProp = "total";
  orderAsc = false;
  sortingIndex: number = 0;

  keyword: string = "";
  creativesKeyword: string = "";
  isInputFocused: boolean = false;

  idTestingGraph: string = this.GenerateRandomString();
  idPerformanceGraph: string = this.GenerateRandomString();
  idDistributionGraph: string = this.GenerateRandomString();
  idEvolutionOfWinnersGraph: string = this.GenerateRandomString();
  xValuesDistribution: number[] = [];
  topVideos: VideoRanking[] = [];

  isExpanded = false;

  performanceGraphLoaded: boolean = false;
  testingGraphLoaded: boolean = false;
  distributionGraphLoaded: boolean = false;
  evolutionGraphLoaded: boolean = false;
  videosNumber: number = 0;
  companiesNumber: number = 0;
  shareUrl: string = "";
  urlOptionalExtension = "";
  encryptedData: string = "";
  isPayingUser: boolean = false;
  @ViewChild("dashboardContainer") dashboardContainer!: ElementRef;
  @ViewChild(SearchSuggestionsComponent)
  suggestionsComponent!: SearchSuggestionsComponent;

  @ViewChild("elementToCapture") elementToCapture!: ElementRef;
  swipeBoards: SwipeBoard[] = [];
  inputControl: FormControl = new FormControl();

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private popupService: PopupService,
    public screenShotService: ScreenshotService
  ) {}

  async ngOnInit(): Promise<void> {
    this.LoadSwipeBoards();
    this.GetRouteInfo();
    this.SubscribeFormControl();
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.ScrollToTop();
      this.SubscribeSearchEvent();
      this.SubscribeToUser();
    }, 300);
  }

  private SubscribeFormControl() {
    this.inputControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.creativesKeyword = this.inputControl.value;
      });
  }

  ScreenshotData(id: string) {
    const element = document.getElementById(id);
    if (element) this.screenShotService.CaptureWithWatermark(element);
  }

  async SubscribeSearchEvent() {
    const input$ = fromEvent(this.overlayInput.nativeElement, "input");
    const search$ = input$.pipe(debounceTime(DEBOUNCE_TIME));
    search$.subscribe(async (event: any) => {
      this.suggestionsComponent.SearchPreview();
    });
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
      if (!user) return;
      if (!user?.hasWatchedEntity) this.popupService.OpenEntityTutorial();
    });
  }

  private async LoadData() {
    this.ResetChartDefaults();
    if (this.type == SearchType.Companies) {
      await Promise.all([
        this.LoadCompanyInfo(),
        this.LoadCompanyPerformanceData(),
        this.LoadCompanyTestingData(),
        this.LoadCompanyDistributionSuccess(),
        this.LoadCompaniesEvolutionOfWinners(),
      ]);
    } else if (this.type == SearchType.Brands) {
      await Promise.all([
        this.LoadBrandInfo(),
        this.LoadBrandPerformanceData(),
        this.LoadBrandTestingData(),
        this.LoadBrandDistributionSuccess(),
        this.LoadBrandEvolutionOfWinners(),
      ]);
    } else if (this.type == SearchType.Offers) {
      await Promise.all([
        this.LoadOfferInfo(),
        this.LoadOfferPerformanceData(),
        this.LoadOfferTestingData(),
        this.LoadOfferDistributionSuccess(),
        this.LoadOfferEvolutionOfWinners(),
      ]);
    }
  }

  private async LoadBrandInfo() {
    const result = await this.apiService.LoadBrandById(
      this.brandId,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.brandResponse = result.data;
    }
  }
  private async LoadBrandPerformanceData() {
    this.performanceGraphLoaded = false;
    const result = await this.apiService.LoadBrandPerformanceStats(
      this.brandId,
      this.chartTypePerformance,
      this.chartPeriodPerformance.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesPerformance = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesPerformance = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.performanceGraphLoaded = true;
    }
  }
  private async LoadBrandTestingData() {
    this.testingGraphLoaded = false;
    const result = await this.apiService.LoadBrandTestingStats(
      this.brandId,
      this.chartTypeTesting,
      this.chartPeriodTesting.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesTesting = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesTesting = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.testingGraphLoaded = true;
    }
  }
  private async LoadBrandDistributionSuccess() {
    this.distributionGraphLoaded = false;
    const result = await this.apiService.LoadBrandDistributionSuccess(
      this.brandId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.distributionSuccessChartData = result.data;
      this.xValuesDistribution = Object.values(
        this.distributionSuccessChartData.spendPercentiles
      );
      this.distributionGraphLoaded = true;
    }
  }
  private async LoadBrandEvolutionOfWinners() {
    this.evolutionGraphLoaded = false;
    const result = await this.apiService.LoadBrandEvolutionOfWinners(
      this.brandId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.topVideos = result.data.videos;
      this.evolutionGraphLoaded = true;
    }
  }

  private async LoadCompanyInfo() {
    const result = await this.apiService.LoadCompanyById(
      this.companyId,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.companyResponse = result.data;
    }
  }
  private async LoadCompanyPerformanceData() {
    this.performanceGraphLoaded = false;
    const result = await this.apiService.LoadCompanyPerformanceStats(
      this.companyId,
      this.chartTypePerformance,
      this.chartPeriodPerformance.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesPerformance = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesPerformance = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.performanceGraphLoaded = true;
    }
  }
  private async LoadCompanyTestingData() {
    this.testingGraphLoaded = false;
    const result = await this.apiService.LoadCompanyTestingStats(
      this.companyId,
      this.chartTypeTesting,
      this.chartPeriodTesting.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesTesting = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesTesting = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.testingGraphLoaded = true;
    }
  }
  private async LoadCompanyDistributionSuccess() {
    this.distributionGraphLoaded = false;
    const result = await this.apiService.LoadCompanyDistributionSuccess(
      this.companyId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.distributionSuccessChartData = result.data;
      this.xValuesDistribution = Object.values(
        this.distributionSuccessChartData.spendPercentiles
      );
      this.distributionGraphLoaded = true;
    }
  }
  private async LoadCompaniesEvolutionOfWinners() {
    this.evolutionGraphLoaded = false;
    const result = await this.apiService.LoadCompanyEvolutionOfWinners(
      this.companyId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.topVideos = result.data.videos;
      this.evolutionGraphLoaded = true;
    }
  }

  private async LoadOfferInfo() {
    const result = await this.apiService.LoadOfferById(
      this.offerId,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.offerResponse = result.data;
    }
  }
  private async LoadOfferPerformanceData() {
    this.performanceGraphLoaded = false;
    const result = await this.apiService.LoadOfferPerformanceStats(
      this.offerId,
      this.chartTypePerformance,
      this.chartPeriodPerformance.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesPerformance = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesPerformance = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.performanceGraphLoaded = true;
    }
  }
  private async LoadOfferTestingData() {
    this.testingGraphLoaded = false;
    const result = await this.apiService.LoadOfferTestingStats(
      this.offerId,
      this.chartTypeTesting,
      this.chartPeriodTesting.replace(/\s/g, "").toLowerCase(),
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.xValuesTesting = result.data.dataPoints.map((entity) =>
        Math.floor(entity.value)
      );
      this.yValuesTesting = result.data.dataPoints.map((spend) =>
        new Date(spend.date).toISOString().substring(0, 10)
      );
      this.testingGraphLoaded = true;
    }
  }
  private async LoadOfferDistributionSuccess() {
    this.distributionGraphLoaded = false;
    const result = await this.apiService.LoadOfferDistributionSuccess(
      this.offerId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.distributionSuccessChartData = result.data;
      this.xValuesDistribution = Object.values(
        this.distributionSuccessChartData.spendPercentiles
      );
      this.distributionGraphLoaded = true;
    }
  }
  private async LoadOfferEvolutionOfWinners() {
    this.evolutionGraphLoaded = false;
    const result = await this.apiService.LoadOfferEvolutionOfWinners(
      this.offerId,
      this.isPayingUser,
      this.urlOptionalExtension,
      this.encryptedData
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.topVideos = result.data.videos;
      this.evolutionGraphLoaded = true;
    }
  }

  private GetRouteInfo(): void {
    combineLatest([
      this.route.params,
      this.route.queryParams,
      this.stateService.user$,
    ])
      .pipe(
        map(async ([routeParams, params, user]) => {
          this.isPayingUser = this.stateService.IsPayingUserState(user);
          if (params["keyword"]) this.keyword = params["keyword"];
          if (params["isAffiliate"]) {
            this.isAffiliate = true;
            this.stateService.TabSelected("affiliate-ads");
            this.stateService.ModuleSelected(ModuleType.Affiliate);
          } else {
            this.stateService.TabSelected("search");
            this.stateService.ModuleSelected(ModuleType.Marketer);
          }
          if (params["companyId"]) {
            this.InitCompanyView(Number(params["companyId"]));
          } else if (params["brandId"])
            this.InitBrandView(Number(params["brandId"]));
          else if (params["offerId"]) {
            this.InitOfferView(Number(params["offerId"]));
            this.isAffiliate = true;
          }
          if (routeParams["key"]) {
            this.urlOptionalExtension = "Shared";
            this.encryptedData = routeParams["key"];
            if (!(await this.SetSharingSetup(routeParams["key"]))) return; //if it is wrong link we skip everything
            if (user) {
              //if user has account and is logged in, we redirect him to the real page
              this.Redirect();
              return;
            }
          } else this.CreateShareUrl();
          await this.LoadData();
          // this.ScrollToTop();
        })
      )
      .subscribe();
  }

  private InitCompanyView(companyId: number) {
    this.companyId = companyId;
    this.offerId = 0;
    this.brandId = 0;
    this.type = SearchType.Companies;
    this.typeSearch = SearchType.Companies;
  }

  private InitBrandView(brandId: number) {
    this.brandId = brandId;
    this.companyId = 0;
    this.offerId = 0;
    this.type = SearchType.Brands;
    this.typeSearch = SearchType.Brands;
  }

  private InitOfferView(offerId: number) {
    this.companyId = 0;
    this.brandId = 0;
    this.offerId = offerId;
    this.type = SearchType.Offers;
    this.stateService.TabSelected("offers");
    this.stateService.ModuleSelected(ModuleType.Affiliate);
  }

  public async ChangePerformanceGraphDateRange(period: string) {
    this.chartPeriodPerformance = period;
    await this.LoadPerformanceData();
  }

  public async ChangeTestingGraphDateRange(period: string) {
    this.chartPeriodTesting = period;
    await this.LoadTestingData();
  }

  public async SwitchPerformanceChartType(type: string) {
    this.chartTypePerformance = type;
    await this.LoadPerformanceData();
  }

  public async SwitchTestingChartType(type: string) {
    this.chartTypeTesting = type;
    await this.LoadTestingData();
  }

  private ResetChartDefaults() {
    // this.chartTypeTesting = ChartTestingType.Spend;
    // this.chartPeriodTesting = ChartTestingPeriod.Year;
    // this.chartTypePerformance = ChartPerformanceType.Spends;
    // this.chartPeriodPerformance = ChartPerformancePeriod.Year;
  }

  public async LoadPerformanceData() {
    if (this.type == SearchType.Companies) {
      await this.LoadCompanyPerformanceData();
    } else if (this.type == SearchType.Brands) {
      await this.LoadBrandPerformanceData();
    } else if (this.type == SearchType.Offers) {
      await this.LoadOfferPerformanceData();
    }
  }

  public async LoadTestingData() {
    if (this.type == SearchType.Companies) {
      await this.LoadCompanyTestingData();
    } else if (this.type == SearchType.Brands) {
      await this.LoadBrandTestingData();
    } else if (this.type == SearchType.Offers) {
      await this.LoadOfferTestingData();
    }
  }

  public SubmitSearch() {
    if (!this.stateService.IsUserLoggedIn()) {
      this.popupService.AskForRegister();
      return;
    }
    let route;
    if (this.type == SearchType.Companies) route = "companies";
    if (this.type == SearchType.Brands) route = "brands";
    if (this.type == SearchType.Ads) route = "ads";
    this.router.navigate([`/dashboard/${route}`], {
      queryParams: {
        keyword: this.keyword,
      },
      queryParamsHandling: "merge",
    });
  }

  public InputFieldFocused() {
    this.isInputFocused = true;
    this.suggestionsComponent.SearchPreview();
  }

  public InputFieldUnfocused() {
    this.isInputFocused = false;
    this.suggestionsComponent.HideResultsContainer();
  }

  public InputFocusChanged(val: boolean) {
    this.isInputFocused = false;
  }

  private GenerateRandomString(): string {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    let randomString = "";

    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      randomString += letters.charAt(randomIndex);
    }

    return randomString;
  }

  public OpenVideoInfo(ytVideoId: string) {
    this.popupService.openVideoInfoModal(
      ytVideoId,
      () => {},
      () => {},
      () => {},
      () => {}
    );
  }

  public async DisplayShorts() {
    this.dtype = DisplayType.Shorts;
  }

  public async DisplayVideos() {
    this.dtype = DisplayType.Youtube;
  }

  public ToggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public ScrollToTop(): void {
    const el = document.getElementById("dashboard-container");
    el?.scrollIntoView({ behavior: "instant" });
  }

  public async BrandSwipeSaved(swipeBoardIds: number[]) {
    const result = await this.apiService.SwipeBrand(
      this.brandId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(
      `${this.brandResponse.brand.name} successfuly saved to favorites!`
    );
    this.brandResponse.brand.swiped = true;
  }
  public async CompanySwipeSaved(swipeBoardIds: number[]) {
    const result = await this.apiService.SwipeCompany(
      this.companyId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(
      `${this.companyResponse.company.legalName} successfuly saved to favorites!`
    );
    this.companyResponse.company.swiped = true;
  }

  public async OfferSwipeSaved(swipeBoardIds: number[]) {
    const result = await this.apiService.SwipeOffer(
      this.offerId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(
      `${this.offerResponse.offer.name} successfuly saved to favorites!`
    );
    this.offerResponse.offer.swiped = true;
  }

  public VideosCountCalculated(num: number) {
    this.videosNumber = num;
  }

  public CompaniesCountCalculated(num: number) {
    this.companiesNumber = num;
  }

  public OpenUpgradeModalIfFreeUser() {
    if (!this.isPayingUser && !this.encryptedData) {
      this.popupService.AskForAccountUpgrade();
    }
  }

  public getBlurIfNeeded() {
    return {
      filter:
        !this.isPayingUser && !this.encryptedData ? "blur(7px)" : "blur(0)",
      "user-select":
        !this.isPayingUser && !this.encryptedData ? "none" : "auto",
      "pointer-events":
        !this.isPayingUser && !this.encryptedData ? "none" : "auto",
    };
  }

  public RemoveCompanySwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected company?",
      async () => await this.CompanySwipeRemoved()
    );
  }

  public RemoveBrandSwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected brand?",
      async () => await this.BrandSwipeRemoved()
    );
  }

  public RemoveOfferSwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected offer?",
      async () => await this.OfferSwipeRemoved()
    );
  }

  private async CompanySwipeRemoved() {
    const result = await this.apiService.RemoveSwipedCompany(this.companyId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Company successfuly removed from Swipes.");
    this.companyResponse.company.swiped = false;
  }

  private async BrandSwipeRemoved() {
    const result = await this.apiService.RemoveSwipedBrand(this.brandId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Brand successfuly removed from Swipes.");
    this.brandResponse.brand.swiped = false;
  }

  private async OfferSwipeRemoved() {
    const result = await this.apiService.RemoveSwipedOffer(this.offerId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Offer successfuly removed from Swipes.");
    this.offerResponse.offer.swiped = false;
  }
  public async CreateShareUrl() {
    if (this.companyId != 0)
      this.shareUrl = await this.GetShareUrl({
        companyId: this.companyId,
      });
    else if (this.brandId != 0)
      this.shareUrl = await this.GetShareUrl({
        brandId: this.brandId,
      });
    else
      this.shareUrl = await this.GetShareUrl({
        offerId: this.offerId,
      });
  }

  public async GetShareUrl(data: object) {
    const key = await this.CreateKey(data);
    // return window.location.origin + "/#/shared/" + key + "/entity-info";
    return `${window.location.origin}/#/shared/${encodeURIComponent(
      key
    )}/entity-info`;
  }

  private async CreateKey(data: object) {
    const result = await this.apiService.GetKey(data);
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) return result?.data;
  }

  public get ShareUrl() {
    return this.shareUrl;
  }

  public async SetSharingSetup(key: any) {
    const result = await this.apiService.ResolveKey(key);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data.companyId) {
      this.type = SearchType.Companies;
      this.companyId = Number(result?.data.companyId);
    } else if (result?.data.brandId) {
      this.type = SearchType.Brands;
      this.brandId = Number(result?.data.brandId);
      this.stateService.TabSelected("search");
      this.stateService.ModuleSelected(ModuleType.Marketer);
    } else if (result?.data.offerId) {
      this.type = SearchType.Offers;
      this.offerId = Number(result?.data.offerId);
      this.stateService.TabSelected("affiliates");
      this.stateService.ModuleSelected(ModuleType.Affiliate);
    } else if (result?.data == "unknown") {
      //it means that not correct link was provided
      this.router.navigate(["/dashboard"]);
      return false;
    }
    return true;
  }

  public CheckIfClickAllowed(event: Event) {
    if (this.encryptedData && !this.stateService.IsUserLoggedIn()) {
      event.stopPropagation();
      this.popupService.openRegisterModal();
    } else if (this.encryptedData && !this.isPayingUser) {
      event.stopPropagation();
      this.popupService.AskForAccountUpgrade();
    }
  }

  public Redirect() {
    this.router.navigate(["/dashboard/entity-info"], {
      queryParams: {
        ...(this.companyId > 0 && { companyId: this.companyId }),
        ...(this.brandId > 0 && { brandId: this.brandId }),
        ...(this.offerId > 0 && { offerId: this.offerId }),
      },
    });
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public OpenEntityTutorial() {
    this.popupService.OpenEntityTutorial();
  }
}
