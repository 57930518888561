import { Component, Input } from "@angular/core";
import { FeedItem } from "src/app/models/feedItem";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-feed-item",
  templateUrl: "./feed-item.component.html",
})
export class FeedItemComponent {
  @Input() video: FeedItem = new FeedItem();
  @Input() category: string | undefined = "";
  constructor(
    public apiService: ApiService,
    public popupService: PopupService
  ) {}

  public async VideoEngaged(event: { isLiked: boolean; isDisliked: boolean }) {
    this.video.isLiked = event.isLiked;
    this.video.isDisliked = event.isDisliked;
    await this.EngageVideo();
  }

  public async EngageVideo() {
    const result = await this.apiService.EngageOnVideo({
      ytVideoId: this.video.ytVideoId,
      isLiked: this.video.isLiked,
      isDisliked: this.video.isDisliked,
    });

    if (result.errorMessage) this.popupService.Notify(result.errorMessage);
  }

  public OpenVideoInfoModal() {
    this.popupService.openVideoInfoModal(
      this.video.ytVideoId,
      () => {},
      () => {},
      () => {},
      () => {}
    );
  }
}
