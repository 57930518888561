<!-- <swiper-container [navigation]="true" [slidesPerView]="1" [breakpoints]="swiperBreakpoints" [spaceBetween]="20"
    [centeredSlides]="true" loop="false">
    <swiper-slide class="card" *ngFor="let videoId of ytVideoIds">
        <app-yt-player-controls class="w-100" [ytVideoId]="videoId"></app-yt-player-controls>
    </swiper-slide>
</swiper-container> -->
<h4 class="title text-center mb-5">
    My feed
</h4>
<div class="d-flex flex-column gap-4">
    <div appInView [elementIndex]="i" (inViewport)="onInViewport($event)" *ngFor=" let video of feedData; let
        i=index">
        <app-feed-item [category]="staticDataService.categoriesMap?.get(video.categoryId)?.name"
            [video]="video"></app-feed-item>
    </div>
</div>