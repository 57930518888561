import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "src/app/services/api/api.service";
import { AnalyticsService } from "src/app/services/shared/analytics.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-pick-answer-question",
  templateUrl: "./pick-answer-question.component.html",
  styleUrls: ["../../login/login.component.scss"],
})
export class PickAnswerQuestionComponent implements OnInit {
  question1 = {
    title: "Which of the following best describes you",
    options: [
      "Affiliate advertiser",
      "Chief Marketing Officer",
      "Bussiness owner",
      "I manage ads for a business/agency",
      "Marketing agency owner",
      "Other",
    ],
    answer: "",
  };
  question2 = {
    title:
      "What ad platforms are you advertising on? Please select all applicable options.",
    options: [
      "YouTube",
      "Facebook/Instagram",
      "Google Search",
      "TikTok",
      "LinkedIn",
      "Other",
    ],
    answer: "",
  };
  question3 = {
    title: "What’s your biggest challenge with YouTube advertising?",
    options: [
      "Not sure",
      "Don't know where to start",
      "Can't scale consistenly",
      "My ads don't perform well",
      "I don't have enough time to figure this out",
      "Other",
    ],
    answer: "",
  };
  question4 = {
    title: "How much are you spending per month on paid advertising (in USD)?",
    options: [
      "Under 5k",
      "5k-10k",
      "10k-30k",
      "30k-50k",
      "50k-100k",
      "100k-250k",
      "250k-500k",
      "500k-1M",
      "Over 1M",
    ],
    answer: "",
  };
  question5 = {
    title: "What's your main business website?",
    answer: "",
  };
  question6 = {
    title: "What landing pages are you sending paid advertising to currently?",
    answer: "",
  };

  selectedAnswerIndex = -1;
  selectedQuestionIndex = 1;
  mutipleAnswersSelected: string[] = [];
  totalQuestionsNum = 6;
  email: string = "";
  name: string = "";
  constructor(
    public stateService: StateService,
    public apiService: ApiService,
    public dialogRef: MatDialogRef<PickAnswerQuestionComponent>,
    public rudderstackService: RudderStackService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.stateService.user$.subscribe((user) => {
      if (user) {
        this.email = user.email;
        this.name = user.name;
      }
    });
  }

  public NextQuestion() {
    if (this.selectedQuestionIndex == 1)
      this.question1.answer = this.question1.options[this.selectedAnswerIndex];
    else if (this.selectedQuestionIndex == 2)
      this.question2.answer = this.mutipleAnswersSelected.join(", ");
    else if (this.selectedQuestionIndex == 3)
      this.question3.answer = this.question3.options[this.selectedAnswerIndex];
    this.selectedQuestionIndex++;
    this.selectedAnswerIndex = -1;
  }

  public SelectedSpendChanged(spend: string) {
    this.question4.answer = spend;
  }

  public ToggleAnswer(index: number) {
    if (!this.mutipleAnswersSelected.includes(this.question2.options[index])) {
      this.mutipleAnswersSelected.push(this.question2.options[index]);
      return;
    }
    this.mutipleAnswersSelected = this.mutipleAnswersSelected.filter(
      (answer) => answer != this.question2.options[index]
    );
  }

  public Finish() {
    this.apiService.InsertResponse(
      this.email,
      this.name,
      this.question1.answer,
      this.question2.answer,
      this.question3.answer,
      this.question4.answer,
      this.question5.answer,
      this.question6.answer
    );
    this.rudderstackService.UserFinishedSignupSequence(
      this.stateService.user?.userId,
      this.stateService.user?.email
    );
    this.analyticsService.UserFinishedSignupSequence(
      this.stateService.user?.userId,
      this.stateService.user?.email
    );
    this.dialogRef.close();
  }
}
