import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { DEBOUNCE_TIME2, PageSizes } from "src/app/models/consts";
import { ModuleType } from "src/app/models/enums";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
})
export class BrandsComponent implements OnInit, AfterViewInit {
  keyword: string = "";
  selectedCategoryIds: number[] = [0];
  selectedCountryId: number = 0;
  selectedSoftwareIds: number[] = [];
  selectedDurationId: string = "none";

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];

  page: number = 1;

  loaded: boolean = false;

  // selectedCountryIds: number[] = [0];

  selectedNetworkId: number = 0;
  selectedCategoryId: number = 0;
  isPayingUser: boolean = false;

  inputControl: FormControl = new FormControl();

  constructor(
    public stateService: StateService,
    public router: Router,
    public route: ActivatedRoute,
    public popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("brands");
    this.stateService.ModuleSelected(ModuleType.Marketer);
    this.SubscribeFormControl();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SubscribeRouteParams();
      this.SubscribeToUser();
    }, 100);
  }

  private SubscribeRouteParams() {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((params) => {
        if (params["keyword"]) {
          this.keyword = params["keyword"];
          this.inputControl.patchValue(this.keyword, { emitEvent: false });
        }

        if (params["selectedCategoryIds"]) {
          this.selectedCategoryIds = JSON.parse(params["selectedCategoryIds"]);
        }

        if (params["selectedCountryId"])
          this.selectedCountryId = Number(params["selectedCountryId"]);

        if (params["selectedSoftwareIds"])
          this.selectedSoftwareIds = JSON.parse(params["selectedSoftwareIds"]);

        if (params["selectedDurationId"])
          this.selectedDurationId = params["selectedDurationId"];

        if (params["resultsPerPage"])
          this.resultsPerPage = Number(params["resultsPerPage"]);

        if (params["page"]) {
          this.page = Number(params["page"]);
        }
        this.UpdateRoute();
        this.loaded = true;
      });
  }

  public UpdateRoute() {
    const queryParams: NavigationExtras = {
      queryParams: {
        keyword: this.keyword,
        selectedCategoryIds: JSON.stringify(this.selectedCategoryIds),
        selectedCountryId: this.selectedCountryId,
        selectedSoftwareIds: JSON.stringify(this.selectedSoftwareIds),
        selectedDurationId: this.selectedDurationId,
        resultsPerPage: this.resultsPerPage,
        page: this.page,
      },
      relativeTo: this.route,
      replaceUrl: true,
    };
    this.router.navigate([], queryParams);
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
      if (!user) return;
      if (!user?.hasWatchedBrands) this.popupService.OpenBrandsTutorial();
    });
  }

  private SubscribeFormControl() {
    this.inputControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.keyword = this.inputControl.value;
        this.page = 1;
        this.UpdateRoute();
      });
  }

  public SelectedCategoriesChanged(selectedIds: number[]) {
    this.selectedCategoryIds = selectedIds;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveAllCategories() {
    this.selectedCategoryIds = [0];
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedSoftwareChanged(selectedIds: number[]) {
    this.selectedSoftwareIds = selectedIds;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedCountryChanged(countryId: number) {
    this.selectedCountryId = countryId;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedDurationChanged(durationId: string) {
    this.selectedDurationId = durationId;
    this.page = 1;
    this.UpdateRoute();
  }
  public RemoveCategory(id: number) {
    if (this.selectedCategoryIds.length == 1) {
      this.selectedCategoryIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedCategoryIds = this.selectedCategoryIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveSoftware(id: number) {
    this.selectedSoftwareIds = this.selectedSoftwareIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveCountry(id: number) {
    this.selectedCountryId = 0;
    this.page = 1;
    this.UpdateRoute();
  }

  public CurrentPageChanged(currentPage: number) {
    this.page = currentPage;
    this.UpdateRoute();
  }

  public ResultsPerPageChanged(resultsPerPage: number) {
    this.resultsPerPage = resultsPerPage;
    this.page = 1;
    this.UpdateRoute();
  }

  public OpenBrandsTutorial() {
    this.popupService.OpenBrandsTutorial();
  }

  public CategorySelected(categoryId: number) {
    this.SelectedCategoriesChanged([
      ...new Set([...this.selectedCategoryIds, categoryId]),
    ]);
  }
}
