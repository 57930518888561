<div class="item-container">
    <ng-container *ngIf="video.isInViewport">
        <app-yt-player-controls class="w-100" [ytVideoId]="video.ytVideoId"></app-yt-player-controls>
        <div class="d-flex flex-row justify-content-between">
            <p class="mt-3 mb-0 card-text">Total spend: <span class="card-title1">${{video.totalSpend |
                    valueFormat}}</span></p>
            <p class="mt-3 mb-0 card-text">Ad spend 90: <span class="card-title1">${{video.adSpend90 |
                    valueFormat}}</span></p>
            <p class="mt-3 mb-0 card-text">Publish date: <span class="card-title1">{{video.publishedAt |
                    dateFormat}}</span></p>
            <p (click)="OpenVideoInfoModal()" style="cursor: pointer;" class="card-title1 mt-4 mb-0 "><b>Details</b></p>
        </div>
    </ng-container>
    <div></div>
    <div *ngIf="!video.isInViewport" class="video-skeleton"></div>
    <div class="buttons-container">
        <app-like-dislike (videoEngaged)="VideoEngaged($event)" [isLiked]="video.isLiked"
            [isDisliked]="video.isDisliked"></app-like-dislike>
    </div>
    <div class="category-container">
        <p class="mt-0 mt-sm-2 mb-0 card-text">Category: <span class="card-title1">{{category}}
            </span></p>
    </div>
</div>