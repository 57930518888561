import { Injectable } from "@angular/core";

declare global {
  interface Window {
    analytics: any;
  }
}

enum EventType {
  UserRegistered = "customer_registered",
  UserViewedVidtao = "customer_viewed_vidtao_first_time",
  SwipeBoardCreated = "swipe_board_created",
  TrainingStarted = "training_started",
  SwipeBoardShared = "swipe_board_shared", //when shared swipe board is openned
  NavigatorSessionOpened = "navigator_session_opened",
  SignupSequenceFinished = "signup_sequence_finished",
  RegistrationStarted = "customer_registration_started",
  NonUserStartedVideo = "non_user_stared_video",
  NonUserPopupAppeared = "non_user_popup_appeared",
  ShareSwipeBoard = "share_swipe_board_clicked", //when users clicks on share swipe board
  ShareVideo = "share_video_clicked", //when users clicks on share video
  ShareEntity = "share_entity_clicked", //when users clicks on share company, brand, offer
}

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private analytics: any;

  constructor() {
    this.analytics = window.analytics;
  }

  // Page Views
  public Page(url: string) {
    this.analytics?.page(document.title, {
      name: "page_viewed",
      path: window.location.pathname,
      url: window.location.href,
      title: document.title,
      referrer: document.referrer,
    });
  }
  // User Identification
  public Identify(userId: string, email: string) {
    this.analytics?.identify(userId, {
      name: "user_identified",
      email: email,
    });
  }
  // Event Tracking Methods
  public UserRegistered(
    userId: string = "",
    email: string | null | undefined = "",
    sharingUserType: string | null = null
  ) {
    this.analytics?.track(EventType.UserRegistered, {
      name: EventType.UserRegistered,
      dbUserId: userId,
      dbEmail: email,
      dbSharingUserType: sharingUserType,
    });
  }

  public TrainingStarted(
    userId?: string,
    trainingId?: any,
    trainingName?: string,
    trainingCategory?: string
  ) {
    this.analytics?.track(EventType.TrainingStarted, {
      name: EventType.TrainingStarted,
      dbUserId: userId,
      dbTrainingId: trainingId,
      dbTrainingName: trainingName,
      dbTrainingCategory: trainingCategory,
    });
  }

  public SwipeBoardShared(userId?: string, swipeBoardId?: string) {
    this.analytics?.track(EventType.SwipeBoardShared, {
      name: EventType.SwipeBoardShared,
      dbUserId: userId,
      dbSwipeBoardSharedId: swipeBoardId,
    });
  }

  public RegistrationStarted() {
    this.analytics?.track(EventType.RegistrationStarted, {
      name: EventType.RegistrationStarted,
    });
  }

  public NonUserStartedVideo() {
    this.analytics?.track(EventType.NonUserStartedVideo, {
      name: EventType.NonUserStartedVideo,
    });
  }

  public NonUserPopupAppeared() {
    this.analytics?.track(EventType.NonUserPopupAppeared, {
      name: EventType.NonUserPopupAppeared,
    });
  }

  public UserViewedVidtao() {
    this.analytics?.track(EventType.UserViewedVidtao, {
      name: EventType.UserViewedVidtao,
    });
  }

  public UserStartedTraining(
    userId?: string,
    trainingId?: any,
    trainingName?: string,
    trainingCategory?: string
  ) {
    this.analytics?.track(EventType.TrainingStarted, {
      name: EventType.TrainingStarted,
      dbUserId: userId,
      dbTrainingId: trainingId,
      dbTrainingName: trainingName,
      dbTrainingCategory: trainingCategory,
    });
  }

  public UserClickedOnNavigatorSession(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.NavigatorSessionOpened, {
      name: EventType.NavigatorSessionOpened,
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public UserFinishedSignupSequence(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.SignupSequenceFinished, {
      name: EventType.SignupSequenceFinished,
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareSwipeBoardClicked(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.ShareSwipeBoard, {
      name: EventType.ShareSwipeBoard,
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareVideoClicked(userId?: string, email?: string | null | undefined) {
    this.analytics?.track(EventType.ShareVideo, {
      name: EventType.ShareVideo,
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareEntityClicked(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.ShareEntity, {
      name: EventType.ShareEntity,
      dbUserId: userId,
      dbEmail: email,
    });
  }
}
