import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  HostListener,
  Renderer2,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Company } from "src/app/models/company";
import { Brand } from "src/app/models/brand";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { SearchType } from "src/app/models/enums";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { SearchSuggestionsComponent } from "src/app/components/search-suggestions/search-suggestions.component";
import { DEBOUNCE_TIME } from "src/app/models/consts";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { AnalyticsService } from "src/app/services/shared/analytics.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("expandAnimation", [
      state("collapsed", style({ height: "0px" })),
      state("expanded", style({ height: "320px" })),
      transition("collapsed <=> expanded", animate("0.3s ease-in-out")),
    ]),
  ],
})
export class HomeComponent implements AfterViewInit, OnInit {
  @ViewChild("inputContainer") inputContainer!: ElementRef;
  @ViewChild("overlayContainer") overlayContainer!: ElementRef;
  @ViewChild("overlayInnerContainer") overlayInnerContainer!: ElementRef;
  @ViewChild("overlayInput") overlayInput!: ElementRef;
  @ViewChild(SearchSuggestionsComponent)
  suggestionsComponent!: SearchSuggestionsComponent;

  selectedIndex: number = 0;
  searchType = SearchType;
  type: SearchType = SearchType.Companies;
  keyword = "";
  showResults = false;
  searchResultsCompanies?: SearchResponse<Company> | null;
  searchResultsBrands?: SearchResponse<Brand> | null;
  animationState: string = "collapsed";
  isInputFocused: boolean = false;
  constructor(
    private stateService: StateService,
    private router: Router,
    private popupService: PopupService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private rudderStackService: RudderStackService,
    private analyticsService: AnalyticsService
  ) {}

  async ngOnInit() {
    if (this.stateService.redirectUrl) this.popupService.openLoginModal();
    else if (this.stateService.openRegisterModal)
      this.popupService.openRegisterModal();
    this.rudderStackService.UserViewedVidtao();
    this.analyticsService.UserViewedVidtao();
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.ShowMainScroll();
      this.SubscribeSearchEvent();
    }, 100);
  }
  public ShowMainScroll() {
    this.renderer.removeClass(document.body, "no-scroll");
  }

  async SubscribeSearchEvent() {
    const input$ = fromEvent(this.overlayInput.nativeElement, "input");
    const search$ = input$.pipe(debounceTime(DEBOUNCE_TIME));
    search$.subscribe(async (event: any) => {
      this.suggestionsComponent.SearchPreview();
    });
  }
  public SubmitSearch() {
    if (!this.stateService.IsUserLoggedIn()) {
      // this.popupService.AskForLogin();
      this.popupService.openRegisterModal();
      return;
    }
    this.ShowScroll();
    this.router.navigate(["/search-results"], {
      queryParams: { keyword: this.keyword },
      queryParamsHandling: "merge",
    });
  }

  public InputFieldFocused(event: any) {
    this.isInputFocused = true;
    this.HideScroll();
    this.ShowOverlayContainer();
  }

  public InputFieldUnfocused(event: Event) {
    const clickedElement = event.target as HTMLElement;
    if (!this.overlayInnerContainer.nativeElement.contains(clickedElement)) {
      this.isInputFocused = false;
      this.ShowScroll();
      this.HideOverlayContainer();
    }
  }
  private HideOverlayContainer() {
    this.SetOverlayPosition(0, 0, 0, 0, "none");
  }

  private ShowOverlayContainer() {
    // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var scrollTop = window.scrollY;
    const inputRect = this.inputContainer.nativeElement.getBoundingClientRect();
    var topRelativeToPage = inputRect.top + scrollTop;
    if (window.innerWidth < 600 && scrollTop < 100) {
      topRelativeToPage = topRelativeToPage - 100;
    }
    this.SetOverlayPosition(
      topRelativeToPage,
      inputRect.left,
      inputRect.width,
      inputRect.height,
      "block"
    );
    this.overlayInput.nativeElement.focus({ preventScroll: true });
  }

  private SetOverlayPosition(
    top: number,
    left: number,
    width: number,
    height: number,
    display: string
  ) {
    this.overlayContainer.nativeElement.style.display = display;
    this.overlayInnerContainer.nativeElement.style.top = top + "px";
    this.overlayInnerContainer.nativeElement.style.left = left + "px";
    this.overlayInnerContainer.nativeElement.style.width = width + "px";
    this.overlayInnerContainer.nativeElement.style.height = height + "px";
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    if (this.isInputFocused) this.ShowOverlayContainer();
  }

  public PopularIconClicked(keyword: string) {
    this.keyword = keyword;
    this.SubmitSearch();
  }

  public OpenSignupModal(): void {
    this.popupService.openRegisterModal();
  }

  public CalculateScrollbarWidth() {
    var div = document.createElement("div");
    div.style.width = "100px";
    div.style.height = "100px";
    div.style.overflow = "scroll";
    document.body.appendChild(div);
    var scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
    return scrollbarWidth;
  }

  public ApplyCssPropertyToClass(
    className: string,
    property: string,
    value: string
  ) {
    const elements = document.getElementsByClassName(className);

    for (let i = 0; i < elements.length; i++) {
      this.renderer.setStyle(elements[i], property, value);
    }
  }

  private HideScroll() {
    this.renderer.addClass(document.body, "no-scroll");
    this.ApplyCssPropertyToClass(
      "no-scroll",
      "padding-right",
      this.CalculateScrollbarWidth().toString() + "px"
    );
  }

  private ShowScroll() {
    this.ApplyCssPropertyToClass("no-scroll", "padding-right", "0px");
    this.renderer.removeClass(document.body, "no-scroll");
  }

  public ScrollToElement(elementId: string) {
    const el = document.getElementById(elementId);
    el?.scrollIntoView({ behavior: "smooth" });
  }
}
