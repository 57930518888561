<div class="container mt-5">
    <h1 class="text-center mt-5">Master YouTube Ads: Learn Directly from Competitor Campaigns</h1>
    <div class="subtitle-div">
        <p class="text-center subtitle">Enjoy a 24-Hours Free Trial of Premium Monthly or opt for Premium Yearly, which
            includes expert community access and
            a 7-Day Money-Back Guarantee.</p>
    </div>
    <div class="container my-4">
        <div class="row px-0">
            <div class="col-12 col-lg-3 mb-2 low-pad">

            </div>
            <div class="col-12 col-lg-3 mb-2 low-pad">
                <div class="plan-card">
                    <div class="d-flex">
                        <p class="plan-title mb-1">Free</p>
                        <img style="height: fit-content;" class="ml-2" src="assets/icons/check-icon.png" />
                    </div>
                    <p class="plan-subtitle">Basic</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Basic search and search results</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">100 affiliate searches</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-2 low-pad">
                <div class="plan-card">
                    <div class="d-flex align-items-center">
                        <p class="plan-title mb-0">${{availablePlans.monthly.price}}/mth</p>
                        <img style="height: 35px;" class="ml-2" src="assets/icons/montly-icon.png" />
                    </div>
                    <p class="plan-subtitle mt-2">{{availablePlans.monthly.name}}</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Advanced search, filtering, & results</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Unlimited affiliate advertiser module</p>
                            </div>
                            <!-- <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">7-day free trial, no credit card needed</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 mb-2 low-pad">
                <div class="plan-card">
                    <div class="d-flex align-items-center">
                        <p class="plan-title mb-0">${{availablePlans.yearly.price}}/yr</p>
                        <img style="height: 35px;" class="ml-2" src="assets/icons/yearly-icon.png" />
                    </div>
                    <p class="plan-subtitle mt-2">{{availablePlans.yearly.name}}</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">All features of Premium Monthly</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Exclusive community access</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Expert advice & Feedback</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">7-day money back guarantee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="plan-options-div">
            <div class="mb-5" *ngFor="let section of planData.sections">
                <p class="section-name">{{section.sectionName}}</p>
                <div *ngFor="let prop of section.props" class="row px-0 prop-div">
                    <div class="col-3">
                        <p class="section-prop mb-0 my-3">{{prop.propName}}</p>
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                        <ng-container *ngIf="prop.free == true">
                            <img src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.free == false">
                            <img src="assets/icons/plan-check-false.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.free != true && prop.free != false">
                            <p class="mb-0 aff-text">{{prop.free}}</p>
                        </ng-container>
                    </div>
                    <div class="col-3  d-flex justify-content-center">
                        <ng-container *ngIf="prop.monthly == true">
                            <img src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.monthly == false">
                            <img src="assets/icons/plan-check-false.png" />
                        </ng-container>
                    </div>
                    <div class="col-3  d-flex justify-content-center">
                        <ng-container *ngIf="prop.yearly == true">
                            <img src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.yearly == false">
                            <img src="assets/icons/plan-check-false.png" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="plan-options-div-small-screens">
            <div class="plan-separate">
                <h4 class="mb-3">Free</h4>
                <div class="mb-5" *ngFor="let section of planData.sections">
                    <p class="section-name">{{section.sectionName}}</p>
                    <div *ngFor="let prop of section.props" class="d-flex prop-div prop-div-sc">
                        <p class="section-prop mb-0 my-3 mr-auto">{{prop.propName}}</p>
                        <ng-container *ngIf="prop.free == true">
                            <img style="height: fit-content;" src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.free == false">
                            <img style="height: fit-content;" src="assets/icons/plan-check-false.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.free != true && prop.free != false">
                            <p class="mb-0 aff-text">{{prop.free}}</p>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="plan-separate">
                <h4 class="mb-3">{{availablePlans.monthly.name}}</h4>
                <div class="mb-5" *ngFor="let section of planData.sections">
                    <p class="section-name">{{section.sectionName}}</p>
                    <div *ngFor="let prop of section.props" class="d-flex prop-div prop-div-sc">
                        <p class="section-prop mb-0 my-3 mr-auto">{{prop.propName}}</p>
                        <ng-container *ngIf="prop.monthly == true">
                            <img style="height: fit-content;" src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.monthly == false">
                            <img style="height: fit-content;" src="assets/icons/plan-check-false.png" />
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="plan-separate">
                <h4 class="mb-3">{{availablePlans.yearly.name}}</h4>
                <div class="mb-5" *ngFor="let section of planData.sections">
                    <p class="section-name">{{section.sectionName}}</p>
                    <div *ngFor="let prop of section.props" class="d-flex prop-div prop-div-sc">
                        <p class="section-prop mb-0 my-3 mr-auto">{{prop.propName}}</p>
                        <ng-container *ngIf="prop.yearly == true">
                            <img style="height: fit-content;" src="assets/icons/plan-check-true.png" />
                        </ng-container>
                        <ng-container *ngIf="prop.yearly == false">
                            <img style="height: fit-content;" src="assets/icons/plan-check-false.png" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>