import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SearchSuggestionsComponent } from "src/app/components/search-suggestions/search-suggestions.component";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { ModuleType, SearchType } from "src/app/models/enums";
import { ApiService } from "src/app/services/api/api.service";
import { RecentSearch } from "src/app/models/recentSearch";
import { FeedItem } from "src/app/models/feedItem";
import { FeedDataService } from "src/app/services/shared/feed-data.service";

@Component({
  selector: "app-in-app-home",
  templateUrl: "./in-app-home.component.html",
  styleUrls: ["./in-app-home.component.scss"],
})
export class InAppHomeComponent implements OnInit, AfterViewInit {
  keyword: string = "";
  isInputFocused: boolean = false;
  type: string = "";
  isPayingUser: boolean = false;
  recentSearches: RecentSearch[] = [];
  feedData: FeedItem[] = [];

  isExpanded: boolean = false;

  @ViewChild("overlayInput") overlayInput!: ElementRef;
  @ViewChild("overlayContainer") overlayContainer!: ElementRef;
  @ViewChild("overlayInnerContainer") overlayInnerContainer!: ElementRef;
  @ViewChild("inputContainer") inputContainer!: ElementRef;
  @ViewChild(SearchSuggestionsComponent)
  suggestionsComponent!: SearchSuggestionsComponent;
  constructor(
    public stateService: StateService,
    public apiService: ApiService,
    public popupService: PopupService,
    public route: ActivatedRoute,
    public router: Router,
    public feedService: FeedDataService
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("search");
    this.stateService.ModuleSelected(ModuleType.Marketer);
    this.LoadRecentSearches();
    this.LoadUserFeed();
  }
  async ngAfterViewInit() {
    setTimeout(() => {
      this.SubscribeSearchEvent();
      this.SubscribeUser();
    }, 100);
  }

  async SubscribeSearchEvent() {
    const input$ = fromEvent(this.overlayInput.nativeElement, "input");
    const search$ = input$.pipe(debounceTime(DEBOUNCE_TIME2));
    search$.subscribe(async (event: any) => {
      if (this.keyword.length > 0) this.feedService.UsingSearchInHomePage({});
      this.suggestionsComponent.SearchPreview();
    });
  }

  public SubscribeUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
      // if (!user) return;
      // if (!user?.hasWatchedSearch) this.popupService.OpenSearchTutorial();
    });
  }

  public SubmitSearch() {
    if (!this.stateService.IsUserLoggedIn()) {
      this.popupService.AskForRegister();
      return;
    }
    let route;
    this.feedService.UsingSearchInHomePage({});
    if (this.type == SearchType.Companies) route = "companies";

    if (this.type == SearchType.Brands) route = "brands";

    if (this.type == SearchType.Ads) route = "ads";

    this.router.navigate([`/dashboard/${route}`], {
      queryParams: {
        keyword: this.keyword,
      },
      queryParamsHandling: "merge",
    });
  }

  public InputFieldFocused(event: any) {
    this.isInputFocused = true;
    this.ShowOverlayContainer();
  }

  public InputFieldUnfocused(event: Event) {
    const clickedElement = event.target as HTMLElement;
    if (!this.overlayInnerContainer.nativeElement.contains(clickedElement)) {
      this.isInputFocused = false;
      this.HideOverlayContainer();
    }
  }

  private HideOverlayContainer() {
    this.SetOverlayPosition(0, 0, 0, 0, "none");
  }

  private SetOverlayPosition(
    top: number,
    left: number,
    width: number,
    height: number,
    display: string
  ) {
    this.overlayContainer.nativeElement.style.display = display;
    this.overlayInnerContainer.nativeElement.style.top = top + "px";
    this.overlayInnerContainer.nativeElement.style.left = left + "px";
    this.overlayInnerContainer.nativeElement.style.width = width + "px";
    this.overlayInnerContainer.nativeElement.style.height = height + "px";
  }

  private ShowOverlayContainer() {
    // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const inputRect = this.inputContainer.nativeElement.getBoundingClientRect();
    var topRelativeToPage = inputRect.top;
    if (window.innerWidth < 801) {
      topRelativeToPage = topRelativeToPage - 76;
    }
    this.SetOverlayPosition(
      topRelativeToPage,
      inputRect.left,
      inputRect.width,
      inputRect.height,
      "block"
    );
    this.overlayInput.nativeElement.focus({ preventScroll: true });
  }

  public DisplayTypeChanged(type: string) {
    this.type = type;
  }

  public async LoadRecentSearches() {
    const result = await this.apiService.GetRecentSearches();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data?.results) this.recentSearches = result.data.results;
  }

  public async LoadUserFeed() {
    const result = await this.apiService.GetUserFeed();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data?.results) this.feedData = result?.data?.results;
  }

  public async OpenRankingsPage() {
    if (this.isPayingUser) await this.router.navigate(["/dashboard/rankings"]);
    else this.popupService.AskForAccountUpgrade();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    if (this.isInputFocused) this.ShowOverlayContainer();
  }

  public async OpenEntityPage(item: RecentSearch) {
    this.feedService.UsingSuggestionsInHomePage({});
    if (item.brandId)
      this.router.navigate(["dashboard/entity-info"], {
        queryParams: { brandId: item.brandId },
      });
    else
      this.router.navigate(["dashboard/entity-info"], {
        queryParams: {
          companyId: item.companyId,
        },
      });
  }

  public OpenSearchTutorial() {
    this.popupService.OpenSearchTutorial();
  }
}
