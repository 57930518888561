import { firebaseConfig } from "../app/services/firebase/config/firebase-config";
export const environment = {
  domain: "https://v2.vidtao.com/#",
  // domain: "http://localhost:4200/#",
  production: false,
  firebaseConfig: firebaseConfig,
  vendorId: 11542,
  trialPlanId: 65791,
  paddleSandbox: true,
  rudderStackId: "2k6k0cdsLEAWjnrcammZzic2ZPr",
  rudderStackUrl: "https://inceptlyvbmctf.dataplane.rudderstack.com/",
  mapbox: {
    accessToken:
      "pk.eyJ1IjoiZHJhc2tvMTIzIiwiYSI6ImNsbzRhaWd4cTAwMjIybG8yODYydnBuNTYifQ.diWuP1BL6LeEvRyeSNnw3g",
  },

  // apiUrl: "https://dev.api.vidtao.com", //dev - automatski se deploy nakon pusha
  // apiUrl: "http://178.156.141.22:3000",
  // apiUrl: "https://lufor5jdx0.execute-api.us-east-2.amazonaws.com/",
  apiUrl: "https://testapi.vidtao.com",
  // apiUrl: "http://localhost:3000",
};
