import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import "chartjs-adapter-moment";
import "chartjs-plugin-annotation";
import { Video } from "../../../models/video";
import { ApiService } from "../../../services/api/api.service";
import {
  ChartPerformancePeriod,
  DateRangeOptions,
  ModuleType,
} from "src/app/models/enums";
import { VideoCreativeStats } from "src/app/models/videosCreativeStats";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { TranscriptUnit } from "src/app/models/transcriptUnit";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FeedDataService } from "src/app/services/shared/feed-data.service";

// declare var YT: any;

@Component({
  selector: "app-video-detail",
  templateUrl: "./video-detail.component.html",
  styleUrls: ["./video-detail.component.scss"],
})
export class VideoDetailComponent implements OnInit {
  dialogResult: string = "";
  ytVideoId: string = "";
  swipeBoardIds: number[] | undefined = [];
  yValues: string[] = [];
  xValues: number[] = [];

  video: Video = new Video();
  videoStats: VideoCreativeStats = new VideoCreativeStats();
  showTextChecked: boolean = false;
  isPayingUser: boolean = false;
  isExpanded: boolean = false;

  chartPeriod: string = ChartPerformancePeriod.Year;
  selectedRangeIndex: number = DateRangeOptions.Year;
  daysBackwardsArray: number[] = [-30, -365, 0];
  periodMap: Map<string, number> = new Map<string, number>([
    [ChartPerformancePeriod.Month, -30],
    [ChartPerformancePeriod.Year, -365],
    [ChartPerformancePeriod.Total, 0],
  ]);

  shareUrl: string = "";
  swipeBoards: SwipeBoard[] = [];

  videoTranscripts: TranscriptUnit[] = [];
  videoUrl!: SafeResourceUrl;
  startTime: number = 0;
  displayTranscript: boolean = false;
  displayDescription: boolean = false;

  player: any;
  isPlaying: boolean = false;
  currentTime: number = 0;
  duration: number = 0;
  timer: any;
  jumpTime: string = "";

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public router: Router,
    private popupService: PopupService,
    public staticDataService: StaticDataService,
    public activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoDetailComponent>,
    public feedService: FeedDataService
  ) {
    this.ytVideoId = data.ytVideoId;
    this.CreateShareUrl();
  }

  async ngOnInit() {
    this.SubscribeToUser();
    this.SubscribeBackdropClick();
    // this.LoadYouTubeAPI();
    Promise.all([
      this.LoadVideoInfo(),
      this.LoadVideoStats(),
      this.LoadSwipeBoards(),
      this.LoadVideoTranscript(),
    ]);
  }

  private async LoadVideoInfo() {
    const result = await this.apiService.GetVideoInfo(this.ytVideoId, "", "");

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.video = result.data;
      this.feedService.OpeningPopUp({
        ytVideoId: this.ytVideoId,
        brandId: this.video.brandId,
        categoryId: this.video.categoryId,
      });
      this.PopulateGraphvalues(
        this.daysBackwardsArray[this.selectedRangeIndex]
      );
    }
  }

  private async LoadVideoTranscript() {
    const result = await this.apiService.GetVideoTranscript(this.ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.videoTranscripts = result.data;
    }
  }

  private async LoadVideoStats() {
    const result = await this.apiService.GetVideoStatistics(
      this.ytVideoId,
      "",
      ""
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.videoStats = result.data;
      this.videoStats.clickUrls.forEach(
        (cu) => (cu.urlDisplay = cu.url.substring(8))
      );
    }
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  public Close() {
    this.dialogRef.close();
  }

  public get getCurrentUrl() {
    return (
      window.location.origin + "/#/dashboard/ad-info?videoId=" + this.ytVideoId
    );
  }

  public getBlurIfNeeded() {
    return {
      filter: !this.isPayingUser ? "blur(7px)" : "blur(0)",
      "user-select": !this.isPayingUser ? "none" : "auto",
      "pointer-events": !this.isPayingUser ? "none" : "auto",
    };
  }

  public OpenAdInfoPage() {
    if (!this.stateService.IsUserLoggedIn()) {
      // this.Close();
      this.popupService.AskForRegister();
    }
    let isAffiliate =
      this.stateService.moduleType == ModuleType.Affiliate ? true : false;
    this.Close();
    const queryParams: NavigationExtras = {
      queryParams: {
        videoId: this.ytVideoId,
        ...(isAffiliate && { isAffiliate }),
      },
      //replaceUrl: true,
      relativeTo: this.activatedRoute,
    };

    this.router.navigate(["dashboard/ad-info"], queryParams);
  }

  public OpenVideoBrand() {
    let isAffiliate =
      this.stateService.moduleType == ModuleType.Affiliate ? true : false;
    this.Close();
    // this.popupService.OpenBrandPreview(this.video.brandId);
    this.router.navigate(["/dashboard/entity-info"], {
      queryParams: {
        brandId: this.video.brandId,
        ...(isAffiliate && { isAffiliate }),
      },
    });
  }

  public async OpenSelectedCategory() {
    this.Close();
    this.stateService.ModuleSelected(ModuleType.Marketer); // if in affiliate mode to switch to marketer before opening ranking page
    await this.router.navigate(["/dashboard/ads"], {
      queryParams: {
        selectedCategoryIds: JSON.stringify([this.video.categoryId]),
      },
    });
  }

  public OpenInNewTab(url: string) {
    this.feedService.ViewingTheLandingPage({
      ytVideoId: this.ytVideoId,
      brandId: this.video.brandId,
      categoryId: this.video.categoryId,
      landingPageUrl: url,
    });
    window.open(url, "_blank");
  }

  public async SwipeSaved(boardsIds?: number[]) {
    this.swipeBoardIds = boardsIds;
    const result = await this.apiService.SwipeVideo(
      this.ytVideoId,
      this.swipeBoardIds
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    this.video.swiped = true;
    this.popupService.Notify("Video successfuly swiped.");
    this.feedService.SwipingVideo({
      ytVideoId: this.ytVideoId,
      brandId: this.video.brandId,
      categoryId: this.video.categoryId,
    });
    this.dialogResult = "swipe-added";
  }
  public async RemoveSwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected swipe?",
      async () => await this.SwipeRemoved()
    );
    this.dialogResult = "swipe-removed";
  }

  public async SwipeRemoved() {
    const result = await this.apiService.RemoveSwipedVideo(this.ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.video.swiped = false;
    this.popupService.Notify("Video successfuly removed from Swipes.");
  }

  public get ShareUrl() {
    return this.shareUrl;
  }

  public async CreateShareUrl() {
    this.shareUrl = await this.GetShareUrl({
      ytVideoId: this.ytVideoId,
    });
  }

  public async GetShareUrl(data: object) {
    const key = await this.CreateKey(data);
    const userParam = this.isPayingUser ? "v-premium" : "v-free";
    // return window.location.origin + `/#/shared/${key}/ad-info?t=${userParam}`;
    return `${window.location.origin}/#/shared/${encodeURIComponent(
      key
    )}/ad-info?t=${encodeURIComponent(userParam)}`;
  }

  private async CreateKey(data: object) {
    const result = await this.apiService.GetKey(data);
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) return result?.data;
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public CloseModal() {
    this.dialogRef.close(this.dialogResult);
  }

  public SubscribeBackdropClick() {
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close(this.dialogResult); // Close the dialog and pass the desired result
    });
  }

  ConvertTimeToSeconds(time: string): number {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  JumpToVideoSeconds(time: string) {
    this.jumpTime = time;
  }

  public ToggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public ChangeGraphDateRange(period: string) {
    this.chartPeriod = period;
    var daysBackward = this.periodMap.get(this.chartPeriod);
    if (daysBackward != undefined) this.PopulateGraphvalues(daysBackward);
  }

  private PopulateGraphvalues(daysBackward: number) {
    this.xValues = [];
    this.yValues = [];
    const filteredSpend = this.video.spend
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .filter((spend) =>
        daysBackward === 0
          ? true
          : new Date(spend.date) > this.AddDays(new Date(), daysBackward)
      );

    this.xValues = filteredSpend.map((spend) => Math.floor(spend.spend));
    this.yValues = filteredSpend.map((spend) =>
      new Date(spend.date).toISOString().substring(0, 10)
    );
  }

  private AddDays(date: any, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public ViewTranscript() {
    this.displayTranscript = !this.displayTranscript;
    if (this.displayTranscript)
      this.feedService.ClickingTranscriptButton({
        ytVideoId: this.ytVideoId,
        brandId: this.video.brandId,
        categoryId: this.video.categoryId,
      });
  }

  public Watching30Seconds() {
    this.feedService.Watching30SecondsOrMore({
      ytVideoId: this.ytVideoId,
      brandId: this.video.brandId,
      categoryId: this.video.categoryId,
    });
  }

  public Watching80Percent() {
    this.feedService.Watching80Percent({
      ytVideoId: this.ytVideoId,
      brandId: this.video.brandId,
      categoryId: this.video.categoryId,
    });
  }

  public VideoStarted() {
    this.feedService.ClickingWatchVideo({
      ytVideoId: this.ytVideoId,
      brandId: this.video.brandId,
      categoryId: this.video.categoryId,
    });
  }
}
