<ng-container *ngIf="isMultiple">
    <mat-form-field appearance="outline" class="no-line">
        <mat-select multiple [formControl]="networksControl" class="select" placeholder="-Select Network-"
            [(ngModel)]="selectedNetworkIds" disableOptionCentering>
            <mat-option>
                <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No categories found"
                    [formControl]="searchControl" [hideClearSearchButton]="true" placeholderLabel="Find network...">
                </ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger style="display: flex;">
                <mat-icon class="mr-2" [svgIcon]="'categories-icon'"></mat-icon>
                {{selectedNetworkName}}
            </mat-select-trigger>
            <mat-option (click)="NetworkSelected()" [value]="network.affiliateNetworkId"
                *ngFor="let network of filteredNetworks">
                {{ network.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>
<ng-container *ngIf="!isMultiple">
    <mat-form-field appearance="outline" class="no-line">
        <mat-select [formControl]="networksControl" class="select" placeholder="-Select Network-"
            [(ngModel)]="selectedNetworkId" disableOptionCentering>
            <mat-option>
                <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No categories found"
                    [formControl]="searchControl" [hideClearSearchButton]="true" placeholderLabel="Find network...">
                </ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger style="display: flex;">
                <mat-icon class="mr-2" [svgIcon]="'categories-icon'"></mat-icon>
                {{selectedNetworkName}}
            </mat-select-trigger>
            <mat-option (click)="NetworkSelected()" [value]="network.affiliateNetworkId"
                *ngFor="let network of filteredNetworks">
                {{ network.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>