<div [@expandAnimation]="animationState" style="left: 0px;" [style.top.px]="top"
    class="results-container overflow-hidden optional-position">
    <div class="d-flex p-2 bottom-border">
        <ng-container *ngIf="viewType == moduleType.Marketer">
            <div class="row mx-0 w-100 max-w search-options-container">
                <button [ngClass]="type != searchType.Ads ?  'option-not-selected': 'option-selected'"
                    (click)="type = searchType.Ads; LoadVideoAds();" class="search-option-button col-4">
                    <mat-icon style="width: 20px; height: 20px;" class="mr-2 d-none d-sm-block"
                        [svgIcon]="'menu-5'"></mat-icon>
                    Ads
                </button>
                <button [ngClass]=" type != searchType.Brands ? 'option-not-selected': 'option-selected'"
                    (click)="type = searchType.Brands; LoadBrands();" class="search-option-button col-4">
                    <mat-icon style="width: 20px; height: 20px;" class="mr-2 d-none d-sm-block"
                        [svgIcon]="'menu-6'"></mat-icon>
                    Brands
                </button>
                <button [ngClass]=" type != searchType.Companies ? 'option-not-selected': 'option-selected'"
                    (click)="type = searchType.Companies; LoadCompanies();" class="search-option-button col-4">
                    <mat-icon style="width: 20px; height: 20px;" class="mr-2 d-none d-sm-block"
                        [svgIcon]="'menu-7'"></mat-icon>
                    Companies
                </button>
            </div>
        </ng-container>
    </div>
    <div *ngIf="type == searchType.Companies">
        <div *ngFor="let result of searchResultsCompanies?.results" class="search-option p-2">
            <div class="d-flex" (click)="OpenSelectedCompany(result)">
                <div class="rounded-icon">
                    <span class="icon-letter">{{ result.legalName | uppercase | slice:0:1 }}</span>
                </div>
                <p [innerHTML]="result.legalName | matchingLetter: keyword" class="my-auto pl-2">

                </p>
                <p class="my-auto pl-2">
                    ({{staticDataService.countriesMap?.get(result.countryId)?.name}})
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="type == searchType.Brands">
        <div *ngFor="let result of searchResultsBrands?.results" class="search-option p-2">
            <div class="d-flex thumbnail" (click)="OpenSelectedBrand(result)">
                <img style="width: 40px; height: 40px; border-radius: 50%;" [src]='result.thumbnail' />
                <p [innerHTML]="result.name | matchingLetter: keyword" class="my-auto pl-2">
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="type == searchType.Ads">
        <div *ngFor="let result of searchResultsVideoAds?.results" class="search-option p-2">
            <div class="d-flex thumbnail" (click)="OpenSelectedAd(result)">
                <img style="width: 40px; height: 40px; border-radius: 50%;" [src]='result.thumbnail' />
                <p [innerHTML]="result.title | matchingLetter: keyword" class="my-auto pl-2">
                </p>
            </div>
        </div>
    </div>


</div>