import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { DEBOUNCE_TIME2, PageSizes } from "src/app/models/consts";
import { ModuleType } from "src/app/models/enums";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-affiliate-ads",
  templateUrl: "./affiliate-ads.component.html",
})
export class AffiliateAdsComponent implements OnInit, AfterViewInit {
  keyword: string = "";
  selectedCategoryIds: number[] = [0];
  selectedNetworkIds: number[] = [0];
  selectedOfferIds: number[] = [];
  selectedCountryId: number = 0;
  selectedLanguageId: string = "";
  selectedStatusId: string = "all";
  selectedDurationId: string = "";
  sortProp = "total";
  orderAsc = false;
  sortingIndex: number = 1;

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];

  page: number = 1;

  loaded: boolean = false;

  openedYtVideoId: string = "";
  firstLoad: boolean = true;
  inputControl: FormControl = new FormControl();

  isPayingUser: boolean = false;
  selectedInterval: string = "last30Days";

  constructor(
    public stateService: StateService,
    public router: Router,
    public route: ActivatedRoute,
    public popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("affiliate-ads");
    this.stateService.ModuleSelected(ModuleType.Affiliate);
    this.SubscribeFormControl();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      if (!user) return;
      if (!user?.hasWatchedAffiliate) this.popupService.OpenAffiliateTutorial();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SubscribeRouteParams();
      this.SubscribeToUser();
    }, 300);
  }

  private SubscribeRouteParams() {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((params) => {
        if (params["keyword"]) {
          this.keyword = params["keyword"];
          this.inputControl.patchValue(this.keyword, { emitEvent: false });
        }

        if (params["selectedCategoryIds"])
          this.selectedCategoryIds = JSON.parse(params["selectedCategoryIds"]);

        if (params["selectedNetworkIds"])
          this.selectedNetworkIds = JSON.parse(params["selectedNetworkIds"]);

        if (params["selectedOfferIds"])
          this.selectedOfferIds = JSON.parse(params["selectedOfferIds"]);

        if (params["selectedCountryId"])
          this.selectedCountryId = params["selectedCountryId"];

        if (params["selectedLanguageId"])
          this.selectedLanguageId = params["selectedLanguageId"];

        if (params["selectedInterval"])
          this.selectedInterval = params["selectedInterval"];

        if (params["selectedStatusId"])
          this.selectedStatusId = params["selectedStatusId"];

        if (params["selectedDurationId"])
          this.selectedDurationId = params["selectedDurationId"];

        if (params["resultsPerPage"])
          this.resultsPerPage = Number(params["resultsPerPage"]);

        if (params["page"]) {
          this.page = Number(params["page"]);
        }

        if (params["sortProp"]) {
          this.sortProp = params["sortProp"];
        }

        if (params["sortingIndex"]) {
          this.sortingIndex = Number(params["sortingIndex"]);
        }

        if (params["orderAsc"]) {
          this.orderAsc = params["orderAsc"] == "true" ? true : false;
        }

        this.UpdateRoute();
        this.loaded = true;
      });
  }

  public UpdateRoute() {
    const queryParams: NavigationExtras = {
      queryParams: {
        keyword: this.keyword,
        selectedCategoryIds: JSON.stringify(this.selectedCategoryIds),
        selectedNetworkIds: JSON.stringify(this.selectedNetworkIds),
        selectedOfferIds: JSON.stringify(this.selectedOfferIds),
        // ...(this.openedYtVideoId && { videoId: this.openedYtVideoId }),
        selectedCountryId: this.selectedCountryId,
        selectedLanguageId: this.selectedLanguageId,
        selectedInterval: this.selectedInterval,
        selectedStatusId: this.selectedStatusId,
        selectedDurationId: this.selectedDurationId,
        resultsPerPage: this.resultsPerPage,
        orderAsc: this.orderAsc,
        sortProp: this.sortProp,
        sortingIndex: this.sortingIndex,
        page: this.page,
      },
      relativeTo: this.route,
      replaceUrl: true,
    };
    this.router.navigate([], queryParams);
  }

  private SubscribeFormControl() {
    this.inputControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.keyword = this.inputControl.value;
        this.page = 1;
        this.UpdateRoute();
      });
  }

  public SortingChanged({ sortProp, orderAsc, sortingIndex }: any) {
    this.sortProp = sortProp;
    this.orderAsc = orderAsc;
    this.sortingIndex = sortingIndex;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedCategoriesChanged(selected: number[]) {
    this.selectedCategoryIds = selected;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveAllCategories() {
    this.selectedCategoryIds = [0];
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedNetworksChanged(selected: number[]) {
    this.selectedNetworkIds = selected;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedOffersChanged(selected: number[]) {
    this.selectedOfferIds = selected;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedCountryChanged(countryId: number) {
    this.selectedCountryId = countryId;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedLanguageChanged(languageId: string) {
    this.selectedLanguageId = languageId;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedStatusChanged(videoStatusId: string) {
    this.selectedStatusId = videoStatusId;
    this.page = 1;
    this.UpdateRoute();
  }

  public selectedDurationChanged(durationId: string) {
    this.selectedDurationId = durationId;
    this.page = 1;
    this.UpdateRoute();
  }

  public CurrentPageChanged(currentPage: number) {
    this.page = currentPage;
    this.UpdateRoute();
  }

  public ResultsPerPageChanged(resultsPerPage: number) {
    this.resultsPerPage = resultsPerPage;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveNetwork(id: number) {
    if (this.selectedNetworkIds.length == 1) {
      this.selectedNetworkIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedNetworkIds = this.selectedNetworkIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveOffer(id: number) {
    if (this.selectedOfferIds.length == 1) {
      this.selectedOfferIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedOfferIds = this.selectedOfferIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }
  public RemoveCategory(id: number) {
    if (this.selectedCategoryIds.length == 1) {
      this.selectedCategoryIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedCategoryIds = this.selectedCategoryIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveCountry(id: number) {
    this.selectedCountryId = 0;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveLanguage(id: string) {
    this.selectedLanguageId = "";
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveDuration(durationId: string) {
    this.selectedDurationId = "";
    this.page = 1;
    this.UpdateRoute();
  }

  public VideoModalOpened(ytVideoId: string) {
    this.openedYtVideoId = ytVideoId;
  }

  public VideoModalClosed() {
    this.openedYtVideoId = "";
  }

  public OpenVideoModal() {
    this.popupService.openVideoInfoModal(
      this.openedYtVideoId,
      () => this.VideoModalClosed(),
      () => {},
      () => {},
      () => {}
    );
  }

  public OpenAffiliateTutorial() {
    this.popupService.OpenAffiliateTutorial();
  }

  public IntervalSelected(interval: string) {
    this.selectedInterval = interval;
    this.page = 1;
    this.UpdateRoute();
  }
}
